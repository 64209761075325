import * as S from "./Paragraphs.elements";
import Heading from "../Heading/Heading";
import Wrapper from "../Wrapper/Wrapper";
import React from "react";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {parseHTMLText} from "../../helpers/parseHTMLText";

export const Paragraphs = ({data}) => {
    const {langForInUrl} = useCurrentLangCode();

    if(!data || !data?.length) return null;

    return (
        <Wrapper last="true" $exclude="top">
            <S.StyledContainer>
                {data.map(item => (
                    <S.Paragraph key={item.id}>
                        {item.type === "text" ? (
                            <S.Paragraph key={item.id}>
                                {item.title && <Heading tag="h2">{item.title}</Heading>}
                                <S.StyledRichText dangerouslySetInnerHTML={{__html: parseHTMLText(item.text, langForInUrl)}} />
                            </S.Paragraph>
                        ) : item.type === "image" ? (
                            <S.Paragraph key={item.id}>
                                <S.Image src={item.imageUrl} alt="" />
                            </S.Paragraph>
                        ) : (
                            <></>
                        )}
                    </S.Paragraph>
                ))}
            </S.StyledContainer>
        </Wrapper>
    );
}