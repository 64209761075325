import React from 'react';
import * as S from './Footer.elements';
import { HiArrowSmRight, HiOutlinePhone, HiOutlineMail, HiOutlineLocationMarker } from 'react-icons/hi';
import { FaInstagram } from 'react-icons/fa';
import { RiFacebookCircleLine, RiAppleLine } from 'react-icons/ri';
import { LuTwitter } from 'react-icons/lu';
import { PiLinkedinLogoBold, PiAndroidLogoBold } from 'react-icons/pi';
import logo from '../../assets/img/logo-inverted.svg';
import iso from '../../assets/img/iso27001.svg';
import decoration from '../../assets/img/decorations/deco-half-circle.svg';
import {Await, Link, useLoaderData} from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import Button from "../Button/Button";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";
import Container from "../Container/Container";
import {Trans, useTranslation} from "react-i18next";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

const Footer = () => {
    const {data} = useLoaderData();
    const { t } = useTranslation();
    const {currentLangCode, langForInUrl} = useCurrentLangCode();

    return (
        <S.Footer>
            <Container>
                <S.FooterContact>
                    <S.Body>
                        <Heading tag="h2">{t('footer.banner_title')}</Heading>
                        <Text>{t('footer.banner_text')}</Text>
                    </S.Body>
                    <Button $onPrimary="true" to={`${langForInUrl}/contact-us`}>{t('footer.banner_button')} <HiArrowSmRight/></Button>
                    <S.Decoration src={decoration} draggable="false" loading="lazy" alt="" />
                </S.FooterContact>
            </Container>

            <S.FooterContainer>
                <S.AboutTMSQR>
                    <Link to={`${langForInUrl}/`} aria-label="home">
                        <S.Logo src={logo} alt="The logo of TMSQR" loading="lazy" />
                    </Link>
                    <p>{t('footer.text')}</p>
                    <S.IconList $apps>
                        <a href="https://apps.apple.com/nl/app/timesquare/id731062580" target="_blank" rel="noreferrer"><RiAppleLine /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.zooma.timesquare" target="_blank" rel="noreferrer"><PiAndroidLogoBold /></a>
                    </S.IconList>
                    <p><Trans i18nKey="footer.creator">Part of <a href={currentLangCode?.code === "nl" ? "https://zoomagroup.nl/" : "https://zoomagroup.nl/en"} target="_blank" rel="noreferrer">Zooma Group</a></Trans></p>
                    <S.Badge href="https://zooma.nl/blog/zooma-certificeert-in-een-keer-op-iso-27001" target="_blank" rel="noreferrer"><img src={iso} alt="" loading="lazy" /></S.Badge>
                </S.AboutTMSQR>
                <S.Nav>
                    <h3>{t('footer.menu_label')}</h3>
                    <ul>
                        <li><Link to={`${langForInUrl}/`}>{t('navbar.home_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/functionalities`}>{t('navbar.functionalities_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/blog`}>{t('navbar.blogsAndLabs_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/pricing`}>{t('navbar.pricing_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/about`}>{t('navbar.about_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/tmsqr-network`}>{t('navbar.network_label')}</Link></li>
                    </ul>
                </S.Nav>
                <S.Nav>
                    <h3>{t('footer.cases_label')}</h3>
                    <React.Suspense fallback={
                        <ul>
                            {Array.from({length: 6}, (_, index) => (
                                <li key={index}><Skeleton width="50%"/></li>
                            ))}
                        </ul>
                    }>
                        <Await resolve={data}>
                            {(data) => (
                                <ul>
                                    {data?.filter(item => Boolean(item?.inFooter))?.map(item => (
                                        item.slug ? (
                                            <li key={item.id}>
                                                <Link to={`${langForInUrl}/cases/${item.slug}`}>{item.title}</Link>
                                            </li>
                                        ) : (
                                            <li key={item.id} className="disabled">
                                                <Link to={`${langForInUrl}/cases`}>{item.title}</Link>
                                            </li>
                                        )
                                    ))}
                                    <li>
                                        <Link to={`${langForInUrl}/cases`}>{t('footer.allCases_label')}</Link>
                                    </li>
                                </ul>
                            )}
                        </Await>
                    </React.Suspense>
                </S.Nav>
                <S.Nav>
                    <h3>{t('footer.contact_label')}</h3>
                    <ul>
                        <li><a href="tel:+31712034054"><HiOutlinePhone /> +31 71 2034054</a></li>
                        <li><a href="mailto:info@tmsqr.com"><HiOutlineMail /> info@tmsqr.com</a></li>
                        <li><a href="https://www.google.com/maps/place/Dorus+Rijkersweg+15,+2315+WC+Leiden/data=!4m2!3m1!1s0x47c5c69d22ac9f61:0x8e014dc1d00a1e65?sa=X&ved=2ahUKEwiFxpPzsub1AhWHvaQKHe3DCesQ8gF6BAgYEAE" target="_blank" rel="noreferrer" className="increaseLineheight"><HiOutlineLocationMarker /> Dorus Rijkersweg 15<br/>2315WC, Leiden<br />The Netherlands</a></li>
                    </ul>
                    <S.IconList>
                        <a href="https://www.instagram.com/tmsqr.app/" target="_blank" rel="noreferrer"><FaInstagram /></a>
                        <a href="https://www.facebook.com/TMSQR/" target="_blank" rel="noreferrer"><RiFacebookCircleLine /></a>
                        <a href="https://www.linkedin.com/company/tmsqr/" target="_blank" rel="noreferrer"><PiLinkedinLogoBold /></a>
                        <a href="https://twitter.com/tmsqr_app" target="_blank" rel="noreferrer"><LuTwitter /></a>
                    </S.IconList>
                </S.Nav>
            </S.FooterContainer>

            <S.SubFooter>
                <S.StyledText><Link to={`${langForInUrl}/terms-and-conditions`}>{t('footer.termsAndConditions_label')}</Link></S.StyledText>
                <S.StyledText><Link to={`${langForInUrl}/privacy-policy`}>{t('footer.privacyPolicy_label')}</Link></S.StyledText>
                <S.StyledText><Link to={`${langForInUrl}/cookies`}>{t('footer.cookies_label')}</Link></S.StyledText>
                <S.StyledText>{t('footer.copyright_label')} &copy; {new Date().getFullYear()} TMSQR</S.StyledText>
            </S.SubFooter>
        </S.Footer>
    )
}

export default Footer
