import React, { useState, useEffect } from 'react'
import * as S from './TextParallax.elements';
import decoration from '../../assets/img/decorations/deco-dual-hexagons.svg';
import { HiArrowSmRight } from 'react-icons/hi';
import image1 from "../../assets/img/blocks/textParallax/1.jpg";
import image2 from "../../assets/img/blocks/textParallax/2.jpg";
import image3 from "../../assets/img/blocks/textParallax/3.jpg";
import Container from "../../components/Container/Container";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {parseHTMLText} from "../../helpers/parseHTMLText";

const TextParallax = ({data}) => {
    const {langForInUrl} = useCurrentLangCode();

    // Handle parallax on mobile
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if(window.innerWidth <= 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <S.Wrapper secondary="true">
            <Container>
                <S.Flex>
                    <S.Box>
                        <S.Decoration src={decoration} alt='' />
                        <Heading tag="h2">{data[0]?.cooperationTitle}</Heading>
                        <S.StyledRichText dangerouslySetInnerHTML={{__html: parseHTMLText(data[0]?.cooperationText, langForInUrl)}} />
                        <Button primary="true" to={`${langForInUrl}/${data[0].cooperationButtonLink}`}>{data[0]?.cooperationButtonLabel} <HiArrowSmRight/></Button>
                    </S.Box>

                    <S.PhoneBox>
                        <S.ParallaxGrid>
                            <S.ParallaxItem as={mobile ? "div" : ""} speed={-20}>
                                <figure>
                                    <img src={image1} alt="" draggable="false"/>
                                </figure>
                            </S.ParallaxItem>
                            <S.ParallaxItem as={mobile ? "div" : ""} speed={20}>
                                <figure>
                                    <img src={image2} alt="" draggable="false"/>
                                </figure>
                                <figure className="figure--margin">
                                    <img src={image3} alt="" draggable="false"/>
                                </figure>
                            </S.ParallaxItem>
                        </S.ParallaxGrid>
                    </S.PhoneBox>
                </S.Flex>
            </Container>
        </S.Wrapper>
    )
}

export default TextParallax
