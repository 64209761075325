import React from 'react'
import Wrapper from "../../components/Wrapper/Wrapper";
import ContainerSmall from "../../components/ContainerSmall/ContainerSmall";
import Heading from "../../components/Heading/Heading";
import Text from "../../components/Text/Text";
import TextImage from "../../components/TextImage/TextImage";
import {Await} from "react-router-dom";

const TextImageList = ({data}) => {
    return (
        <React.Suspense>
            <Await resolve={data}>
                {(data) => (
                    <Wrapper>
                        <ContainerSmall as="header">
                            <Heading tag="h2">{data[0]?.title}</Heading>
                            <Text>{data[0]?.text}</Text>
                        </ContainerSmall>

                        {data[0]?.textImages?.map(textImage => (
                            <TextImage
                                key={textImage.id}
                                icon={textImage.icon}
                                image={textImage.image}
                                title={textImage.title}
                                text={textImage.text}
                                reverse={textImage?.position === 'right'}
                            />
                        ))}
                    </Wrapper>
                )}
            </Await>
        </React.Suspense>
    )
}

export default TextImageList
