import styled, { keyframes } from 'styled-components';
import background from '../../assets/img/pricing/background.svg';

export const Flex = styled.section`
    display: flex;
    justify-content: space-between;
    margin: 0 -40px;
    
    @media screen and (max-width: 850px) {
        margin: -40px 0;
        flex-direction: column;
    }
`;

export const Box = styled.section`
    flex: 0 1 50%;
    margin: 0 40px;
    
    &:first-of-type {
        padding-top: 60px;
    }

    @media screen and (max-width: 850px) {
        margin: 40px 0;
        width: 100%;

        &:first-of-type {
            padding-top: unset;
        }
    }
`;

export const Accordions = styled.section`
    margin-top: 40px;
`;

const pattern = keyframes`
    100%{
        background-position: 2000px -2000px;
    }
`;

export const ImageWrapper = styled.div`
    background: url(${background}), rgb(246, 246, 246);
    animation: ${pattern} 180s linear infinite;
    border-radius: 50%;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    img {
        max-width: 550px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;