import styled, {css} from 'styled-components';
import { HiChevronDown } from 'react-icons/hi';
import { PiGlobe } from 'react-icons/pi';
import {useRef} from "react";
import {Transition} from "react-transition-group";
import {languagesData} from "../../constants/languages";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {useDropdown} from "../../hooks/useDropdown";

const DropdownWrapper = styled.div`
    position: relative;
`;

const StyledNavbarLanguage = styled.div`
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    padding: 12px;
    margin: 0 0 0 8px;
    font-family: var(--font-button);
    font-size: var(--fs-button);
    font-weight: var(--fw-bold);
    color: var(--color-black);
    line-height: 1;
    text-align: center;
    border-radius: 24px;
    background-size: 0 100%;
    background-image: linear-gradient(to left, rgba(0,0,0,.02), rgba(0,0,0,.02));
    background-repeat: no-repeat;
    transition: background-color .2s ease, background-size .2s ease;

    ${({$open}) => $open && css`
        background-image: linear-gradient(to left, rgba(0,0,0,.02), rgba(0,0,0,.02));
        background-size: 100% 100%;
    `}
    
    &:hover, &:focus {
        background-image: linear-gradient(to left, rgba(0,0,0,.02), rgba(0,0,0,.02));
        background-size: 100% 100%;
    }

    @media screen and (max-width: 1024px){
        margin: 0;
        padding: 8px;
    }
`;

const StyledGlobe = styled(PiGlobe)`
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
`;

const StyledChevron = styled(HiChevronDown)`
    width: 20px;
    height: 20px;
    vertical-align: middle;
    transition: transform .2s ease;

    ${({$open}) => $open && css`
        transform: rotate(-180deg);
    `}

    ${StyledNavbarLanguage}:hover &, ${StyledNavbarLanguage}:focus & {
        transform: rotate(-180deg);
    }
`;

const Dropdown = styled.div`
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(calc(-50% + 8px));
    width: 170px;
    height: 600px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 8px 0;
    z-index: 1;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    opacity: 0;
    transition: opacity .2s ease, top .2s ease;

    &.entering { opacity: 1; top: 55px; }
    &.entered { opacity: 1; top: 55px; }
    &.exiting { opacity: 0; top: 45px; }
    &.exited { opacity: 0; top: 45px; }
    
    @media screen and (max-width: 768px) {
        left: 0;
    }
`;

const DropdownText = styled.p`
    font-size: var(--font-size-nav-dropdown);
    color: var(--color-text);
    font-weight: var(--fw-medium);
    text-decoration: none;
    user-select: text;
`;

const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 20px;
    cursor: pointer;
    background-color: ${({$active}) => $active ? 'var(--color-surface)' : 'transparent'};

    &:not(:last-of-type) {
        margin-bottom: 4px;
    }

    &:hover {
        background-color: var(--color-surface);
    }

    &:active {
        background-color: var(--color-surface);
    }

    ${({$active}) => $active && `
        ${DropdownText} { font-weight: var(--fw-bold); }
    `};

    @media screen and (max-width: 768px) {
        padding: 12px 16px;
    }
`;

export const NavbarLanguage = () => {
    const dropdownRef = useRef(null);
    const nodeRef = useRef(null);
    const {open, setOpen} = useDropdown(dropdownRef)
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {currentLangCode} = useCurrentLangCode();
    const {i18n} = useTranslation();

    const selectLanguage = (langCode) => {
        setOpen(false);

        let newPathname;
        if(Boolean(currentLangCode)) {
            newPathname = langCode === "en"
                ? pathname.replace(`/${currentLangCode.code}/`,  "/")
                : pathname.replace(`/${currentLangCode.code}/`, `/${langCode}/`)
            ;
        } else {
            newPathname = langCode === "en" ? pathname : `/${langCode}${pathname}`;
        }

        i18n.changeLanguage(langCode);

        navigate(newPathname);
    }
    
    return (
        <DropdownWrapper ref={dropdownRef}>
            <StyledNavbarLanguage $open={open}>
                <StyledGlobe /> {Boolean(currentLangCode) ? currentLangCode?.code?.toUpperCase() : "EN"} <StyledChevron $open={open} />
            </StyledNavbarLanguage>

            <Transition nodeRef={nodeRef} in={open} timeout={200} unmountOnExit>
                {state => (
                    <Dropdown ref={nodeRef} className={state}>
                        {languagesData.map(item => (
                            <DropdownItem key={item.code} onClick={() => selectLanguage(item.code)} $active={Boolean(currentLangCode) ? item.code === currentLangCode?.code : item.code === "en"}>
                                <DropdownText>{item.language}</DropdownText>
                            </DropdownItem>
                        ))}
                    </Dropdown>
                )}
            </Transition>
        </DropdownWrapper>
    );
}