import React from 'react';
import {defer, useLoaderData} from "react-router-dom";
import BlogsOverview from "../../components/BlogsOverview/BlogsOverview";
import AtmosphericHero from "../../blocks/AtmosphericHero/AtmosphericHero";
import {Seo} from "../../components/Seo/Seo";
import {useTranslation} from "react-i18next";

export async function blogsAndLabsLoader({request, params}) {
    const lang = params?.lang;

    const blogsLabsPageData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/blogslabs-page${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const blogsData = fetch(`${process.env.REACT_APP_API_BASE_URL}/blogs${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    return defer({blogsLabsPageData, blogsData});
}

const BlogsAndLabs = () => {
    const {t} = useTranslation();
    const {blogsLabsPageData, blogsData} = useLoaderData();

    return (
        <>
            <Seo
                title={t('navbar.blogsAndLabs_label')}
                description={blogsLabsPageData[0]?.metaDescription}
                slug="/blog"
            />

            <AtmosphericHero
                title={blogsLabsPageData[0]?.title}
                text={blogsLabsPageData[0]?.text}
                location="blogs"
            />

            <BlogsOverview
                data={blogsData}
                title={blogsLabsPageData[0]?.blogsTitle}
                description={blogsLabsPageData[0]?.blogsText}
                exclude="top"
                last="true"
            />
        </>
    );
}

export default BlogsAndLabs
