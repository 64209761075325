import axios from "axios";

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

export const getRequest = (URL) => {
    return client.get(`/${URL}`).then(response => response);
}

export const postRequest = (URL, payload) => {
    return client.post(`/${URL}`, payload).then(response => response);
}

export const patchRequest = (URL, payload) => {
    return client.patch(`/${URL}`, payload).then(response => response);
}

export const deleteRequest = (URL) => {
    return client.delete(`/${URL}`).then(response => response);
}

export default client;