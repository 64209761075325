import {Helmet} from "react-helmet-async";
import {languagesData} from "../../constants/languages";
import React from "react";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

export const Seo = ({title, description, slug, image}) => {
    const {currentLangCode} = useCurrentLangCode();

    return (
        <Helmet>
            <title>TMSQR &mdash; {title}</title>
            <meta property="og:title" content={`TMSQR - ${title}`} />
            <meta name="twitter:title" content={`TMSQR - ${title}`} />
            {description && <meta name="description" content={description} />}
            {image && <meta property="og:image" content={image} />}
            {description && <meta property="og:description" content={description} />}
            {description && <meta name="twitter:description" content={description} />}
            {image && <meta name="twitter:image" content={image} />}
            {slug &&
                <>
                    <link rel="canonical" href={`https://tmsqr.com${(currentLangCode && currentLangCode?.code !== "en") ? `/${currentLangCode.code}` : ""}${slug}`} />
                    {languagesData.map(item => (
                        <link key={item.code} rel="alternate" href={`https://tmsqr.com${item.code !== "en" ? `/${item.code}` : ""}${slug}`} hrefLang={item.code} />
                    ))}
                    <link rel="alternate" href={`https://tmsqr.com${slug}`} hrefLang="x-default" />
                </>
            }
        </Helmet>
    )
}