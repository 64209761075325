import React from 'react'
import * as S from './Functionalities.elements';
import 'react-loading-skeleton/dist/skeleton.css'
import {InnerFeatureSwitch} from "../../blocks/FeatureSwitch/FeatureSwitch";
import teamInOffice from '../../assets/img/blocks/textImage/team-in-office.jpg';
import {defer, Outlet, useLoaderData, useParams} from "react-router-dom";
import IntegrationParallax from "../../blocks/IntegrationParallax/IntegrationParallax";
import Container from "../../components/Container/Container";
import Hero from "../../blocks/Hero/Hero";
import Wrapper from "../../components/Wrapper/Wrapper";
import IntegrationCloudInvert from "../../components/IntegrationCloudInvert/IntegrationCloudInvert";
import TextImage from "../../components/TextImage/TextImage";
import FeatureList from "../../components/FeatureList/FeatureList";
import {Seo} from "../../components/Seo/Seo";
import {DeferredBulletSection} from "../../blocks/DeferredBulletSection/DeferredBulletSection";
import {useTranslation} from "react-i18next";

export async function functionalitiesLoader({request, params}) {
    const lang = params?.lang;

    const partnersData = fetch(`${process.env.REACT_APP_API_BASE_URL}/partners${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const functionalitiesData = fetch(`${process.env.REACT_APP_API_BASE_URL}/functionalities${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const bulletSectionData = fetch(`${process.env.REACT_APP_API_BASE_URL}/bullet-section${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const functionalitiesPageData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/functionalities-page${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const featuresBlockData = fetch(`${process.env.REACT_APP_API_BASE_URL}/features-block${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    return defer({partnersData, functionalitiesData, bulletSectionData, functionalitiesPageData, featuresBlockData});
}

const Functionalities = () => {
    const {t} = useTranslation();
    const {partnersData, functionalitiesData, bulletSectionData, functionalitiesPageData, featuresBlockData} = useLoaderData();
    let {slug} = useParams(); // If there is a slug, don't render Seo component to prevent duplicate hreflang tags

    return (
        <>
            {!Boolean(slug) &&
                <Seo
                    title={t('navbar.functionalities_label')}
                    description={functionalitiesPageData[0]?.metaDescription}
                    slug="/functionalities"
                />
            }

            <Hero
                type="overlap"
                heading={functionalitiesPageData[0]?.title}
                text={functionalitiesPageData[0]?.text}
            />

            <S.Functionalities>
                <Container>
                    <S.FeatureSwitchWrapper>
                        <InnerFeatureSwitch data={functionalitiesData} expanded={true} />
                    </S.FeatureSwitchWrapper>
                </Container>
            </S.Functionalities>

            <FeatureList data={featuresBlockData} exclude="top" />

            <DeferredBulletSection data={bulletSectionData} />

            <Wrapper>
                <Container>
                    <IntegrationCloudInvert data={functionalitiesPageData}/>
                </Container>
            </Wrapper>

            <IntegrationParallax data={partnersData} />

            <S.Spacing $last>
                <TextImage
                    image={teamInOffice}
                    radius={true}
                    title={functionalitiesPageData[0]?.improvementTitle}
                    text={functionalitiesPageData[0]?.improvementText}
                    buttonText={functionalitiesPageData[0]?.improvementButtonLabel}
                    buttonUrl={functionalitiesPageData[0]?.improvementButtonUrl}
                    reverse={false}
                    eager={true}
                />
            </S.Spacing>

            <Outlet />
        </>
    )
}

export default Functionalities
