import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: ${props => props.secondary ? 'var(--color-surface)' : props.tertiary ? 'var(--color-background-60)' : 'var(--color-background)'};
    ${props => props.$exclude !== 'top' && 'padding-top: 160px;'};
    ${props => props.$exclude !== 'bottom' && `padding-bottom: ${props.last ? '240px' : '160px'};`}
    position: relative;

    @media screen and (max-width: 768px) {
        ${props => props.$exclude !== 'top' && `padding-top: ${props.first ? '160px' : '80px'};`}
        ${props => props.$exclude !== 'bottom' && `padding-bottom: ${props.last ? '240px' : '80px'};`}
    }
`;

export default Wrapper;