import styled, {css} from 'styled-components';

export const truncateMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ $clamp = 1 }) => `-webkit-line-clamp: ${$clamp};`}
`;

const Text = styled.p`
    color: var(--color-text);
    font-size: var(--font-size-default);
    font-weight: var(--fw-medium);
    line-height: 1.5;
    margin: ${({$noMargin}) => $noMargin ? 0 : '8px 0'};
    ${({ $supportPreWrap }) => $supportPreWrap && 'white-space: pre-wrap;'};
    ${({ $truncate }) => $truncate && truncateMixin};

    a {
        font-weight: 600;
        background: linear-gradient(to bottom, var(--color-surface) 0%, var(--color-surface) 100%);
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 4px 50%;
        text-decoration: none;
        transition: background-size .2s;

        :hover {
            background-size: 4px 100%;
        }
    }

    ul {
        margin: 16px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        @media screen and (max-width: 425px) {
            grid-template-columns: 1fr;
        }
    }

    li {
        &:before {
            content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z' stroke='%2304575D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7 10L9 12L13 8' stroke='%234BC17A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            display: inline-block;
            vertical-align: sub;
            margin-right: 15px;
        }
    }

    ${props => props.white && `${white}`};
`;

const white = css`
    color: var(--color-white);
`;

export const RichText = styled.article`

    h2 {
        font-family: var(--font-heading);
        margin: 8px 0;
        color: var(--color-heading);
        line-height: 1.25;
        font-size: 3rem;
        font-weight: 700;
    }

    h3, h4 {
        font-family: var(--font-heading);
        margin: 8px 0;
        color: var(--color-heading);
        line-height: 1.25;
        font-size: 2.2rem;
        font-weight: 700;
    }

    h4 {
        font-size: var(--fs-h3-smaller);
    }

    p, a, li {
        font-weight: var(--fw-medium);
        color: var(--color-text);
        font-size: var(--font-size-default);
        line-height: 1.5;
        margin: 8px 0;
    }
    
    em {
        font-style: italic;
    }

    a {
        font-weight: var(--fw-semi-bold);
        background: linear-gradient(to bottom, var(--color-surface) 0%, var(--color-surface) 100%);
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 4px 50%;
        text-decoration: none;
        transition: background-size .2s;

        :hover {
            background-size: 4px 100%;
        }
    }

    ul {
        margin: 16px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        @media screen and (max-width: 425px) {
            grid-template-columns: 1fr;
        }
    }

    ul li {
        margin: 0;

        &:before {
            content: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442Z' stroke='%23434343' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7 10L9 12L13 8' stroke='%23FBD904' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            display: inline-block;
            vertical-align: sub;
            margin-right: 15px;
        }
    }
    
    ol li {
        list-style-type: auto;
        margin-left: 16px;
    }
`;

export default Text;