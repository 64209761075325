import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
    align-items: center;
    margin: 0 -40px; 

    @media screen and (max-width: 992px){
        margin: 0;
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Section = styled.section`
    flex: 0 1 50%;
    margin: 0 40px;

    @media screen and (max-width: 992px) {
        margin: 0;
    }
`;

export const Grid = styled.section`
    margin-top: 40px;
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media screen and (max-width: 992px){
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media screen and (max-width: 768px){
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 375px){
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

export const Images = styled.section`
    flex: 0 1 50%;
    position: relative;
    margin: 0 40px;

    @media screen and (max-width: 992px) {
        align-self: center;
        margin: 80px 0 0;
    }
`;

export const Img = styled.img`
    width: ${({$noMockup}) => $noMockup ? '100%' : '80%'};
    max-height: 600px;
    object-fit: cover;
    border-radius: 15px;
    display: block;
    ${props => !props.$reversed && 'margin-left: auto;'}
`;

export const Mockup = styled.img`
    position: absolute;
    top: 50%;
    ${props => props.$reversed ? 'right: 0;' : 'left: 0;'}
    transform: translateY(-50%);
    max-width: 270px;
    height: auto;
    max-height: 100%;
`;