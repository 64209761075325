import styled from 'styled-components';
import Cookies from 'js-cookie';
import Text from "../Text/Text";
import {Link} from "react-router-dom";
import Button from "../Button/Button";
import {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

const StyledCookieBanner = styled.div`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 24px 30px;
    display: flex;
    align-items: center;
    gap: 30px;
    z-index: 98;
    max-width: 800px;
    width: calc(100% - 48px);
    
    ${Text} { 
        margin: 0; 
        flex: 1 1;
    }
    
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        max-width: 500px;
        transform: none;
        left: 24px;
        bottom: 24px;
    }
    
    @media screen and (max-width: 425px) {
        left: 16px;
        bottom: 16px;
        width: calc(100% - 32px);
    }
`;

const ButtonWrapper = styled.div`
    flex-shrink: 0;

    @media screen and (max-width: 1024px) {
        align-self: flex-end;
    }
`;

const StyledButton = styled(Button)`
    display: block;
`;

const CookieBanner = () => {
    const {t} = useTranslation();
    const {langForInUrl} = useCurrentLangCode();
    const [acceptedCookies, setAcceptedCookies] = useState(Boolean(Cookies.get('tmsqr_cookies')));

    const handleAcceptation = () => {
        Cookies.set('tmsqr_cookies', true, {expires: 365, secure: true});
        setAcceptedCookies(true);
    }

    if(acceptedCookies) return null;

    return (
        <StyledCookieBanner>
            <Text><Trans i18nKey="footer.cookieBanner_text">TMSQR uses standard functional cookies to ensure that the website works as expected. By means of analytical cookies we gain insight into how you use our website and how efficient our marketing is. <Link to={`${langForInUrl}/cookies`}>Read more</Link>.</Trans></Text>
            <ButtonWrapper>
                <StyledButton as="button" primary="true" onClick={handleAcceptation}>{t('footer.cookieBanner_button')}</StyledButton>
            </ButtonWrapper>
        </StyledCookieBanner>
    );
}

export default CookieBanner;