import styled from 'styled-components';
import Slider from 'react-slick';

export const StyledCustomerSlider = styled.div`
    padding-top: 100px;
    
    @media screen and (max-width: 768px) {
        padding-top: 40px;
    }
`;

export const Flex = styled.div`
    display: flex;
    border-radius: 10px;
    background-color: var(--color-primary);

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledSlider = styled(Slider)`
    width: 40%;
    overflow: hidden;
    height: 340px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @media screen and (max-width: 768px) {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0;
        width: 100%;
    }

    .slick-dots {
        bottom: 20px;
        left: 50%;
        width: unset;
        transform: translateX(-50%);

        li {
            display: inline-block;
            transition: all .2s;
            width: 8px;
            height: 8px;
            
            button {
                transition: all .2s;
                background-color: var(--color-white);
                border-radius: 7px;
                width: 8px;
                height: 8px;

                &:before {
                    content: none;
                }
            }

            &.slick-active {
                transition: all .2s;
                width: 30px;

                button {
                    background-color: var(--color-primary);
                    width: 30px;
                }
            }
        }
    }

    .slick-slide {
        border: none;
        overflow: hidden;
        
        @media screen and (max-width: 768px) {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 0;
        }

        div {
            outline: none;
        }
    }
`;

export const Content = styled.article`
    width: 60%;
    padding: 40px;
    display: flex;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 24px;
    }

    div {
        position: relative;
        z-index: 1;
        align-self: center;
    }
    
    p {
        margin-bottom: 20px;
    }
`;

export const Decoration = styled.img`
    position: absolute;
    bottom: 0;
    right: 50px;

    @media screen and (max-width: 1024px) {
        right: -150px;
    }
`;

export const Slide = styled.div`
    height: 340px;

    img {
        width: 100%;
        height: 100%;
        /* max-height: 340px; */
        object-fit: cover;
    }

`;
