import React from 'react'
import * as S from './CaseCard.elements';
import { HiArrowSmRight } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";
import {usePopupLinkContext} from "../../contexts/PopupLinkContext";
import {ReactComponent as TMSQR} from "../../assets/img/cases/tmsqr.svg";
import {ReactComponent as Apple} from "../../assets/img/cases/apple.svg";
import {ReactComponent as Android} from "../../assets/img/cases/android.svg";
import {useLocation} from "react-router-dom";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {useTranslation} from "react-i18next";

const CaseCard = ({data, loading}) => {
    const {langForInUrl} = useCurrentLangCode();
    const {t} = useTranslation();
    const {setViaInternalLink} = usePopupLinkContext();
    const {pathname} = useLocation();

    if(loading) {
        return (
            <S.StyledCaseCard>
                <S.SkeletonFigure>
                    <Skeleton height="200px" width="100%" />
                </S.SkeletonFigure>

                <S.InnerCaseCard>
                    <Heading tag="h4"><Skeleton width={120} /></Heading>
                    <Text><Skeleton width={70} /></Text>
                </S.InnerCaseCard>
            </S.StyledCaseCard>
        )
    }

    return (
        <S.StyledCaseCard as={!data.slug ? 'article' : undefined} to={`${langForInUrl}/cases/${data.slug}`} preventScrollReset={pathname.includes("/cases")} onClick={() => setViaInternalLink(true)} $isLink={Boolean(data.slug)}>
            {data.imageUrl &&
                <S.OuterImage>
                    <S.InnerImage src={data.imageUrl} alt="" />
                </S.OuterImage>
            }
            <S.InnerCaseCard>
                <S.Title>
                    <Heading tag="h4">{data.title} {data.slug && <HiArrowSmRight/>}</Heading>
                </S.Title>
                <Text>{data.text}</Text>
                <S.Download>
                    <S.DownloadText>{t('general.downloadTheApp_label')}</S.DownloadText>

                    <S.DownloadList>
                        <S.Icon as={TMSQR} $active={Boolean(data.tmsqrLink)} />
                        <S.Icon as={Apple} $active={Boolean(data.appStoreLink)} />
                        <S.Icon as={Android} $active={Boolean(data.playStoreLink)} />
                    </S.DownloadList>
                </S.Download>
            </S.InnerCaseCard>
        </S.StyledCaseCard>
    )
}

export default CaseCard
