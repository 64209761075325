import styled from 'styled-components';
import Text from '../../components/Text/Text';

export const Card = styled.article`
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
    max-width: 100%;
    width: calc(${({$width}) => $width + 'px'} - 48px); // 48px = total container padding
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 425px) {
        width: calc(${({$width}) => $width + 'px'} - 32px); // 32px = total container padding
    }
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    p {
        margin: 0;
    }
`;

export const Figure = styled.figure`
    margin-right: 16px;
`;

export const Img = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
`;

export const Person = styled(Text)`
    opacity: .7;
    margin-top: auto;
    margin-bottom: 0;
`;