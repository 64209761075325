import React, { useState, useEffect } from 'react'
import * as S from './FeatureSwitch.elements';
import { AnimateSharedLayout } from "framer-motion";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import functionality from "../../assets/img/blocks/featureSwitch/placeholder.jpg";
import Wrapper from "../../components/Wrapper/Wrapper";
import ContainerSmall from "../../components/ContainerSmall/ContainerSmall";
import Heading from "../../components/Heading/Heading";
import Text from "../../components/Text/Text";
import Container from "../../components/Container/Container";
import {Await} from "react-router-dom";

const FeatureSwitch = ({data, expanded = false }) => {
    return (
        <Wrapper>
            <ContainerSmall>
                <Heading tag="h2">Functionalities</Heading>
                <Text>View a selection of the functionalities we can add to your festival app. But we have many more options to offer so please contact us and we will make a custom design or prototype for you without obligation!</Text>
            </ContainerSmall>

            <Container>
                <InnerFeatureSwitch data={data} expanded={expanded} />
            </Container>
        </Wrapper>
    )
}

export const InnerFeatureSwitch = ({data, expanded}) => {
    // Handle Grid
    const [grid, setGrid] = useState(true);

    const showGrid = () => {
        if(window.innerWidth <= 992) {
            setGrid(false);
        } else {
            setGrid(true);
        }
    };

    useEffect(() => {
        showGrid();

        window.addEventListener('resize', showGrid);
        return () => window.removeEventListener('resize', showGrid);
    }, []);

    // Animate selection
    const [selected, setSelected] = useState(functionality);

    // Data is a promise, so chain to set selected
    useEffect(() => {
        data.then((functionalities) => {
            setSelected(functionalities[0].imageUrl);
        });
    }, [data])

    return (
        <>
            {grid ?
                <>
                    <S.Grid $expanded={expanded}>
                        <S.Phone>
                            <img src={selected} alt="" />
                        </S.Phone>

                        <React.Suspense fallback={Array.from({ length: 8 }, (_, index) => (
                            <S.Item key={index} $index={index}>
                                <S.Figure>
                                    <Skeleton
                                        circle
                                        height="40px"
                                        width="40px"
                                    />
                                </S.Figure>
                                <S.Content>
                                    <Heading tag="h4"><Skeleton width={200} /></Heading>
                                    <Text><Skeleton width={180} /></Text>
                                </S.Content>
                            </S.Item>
                        ))}>
                            <Await resolve={data}>
                                {(data) => (
                                    <AnimateSharedLayout>
                                        {data.slice(0, 8).map((item, index) => {
                                            return (
                                                <S.Item
                                                    key={item.id}
                                                    onClick={() => setSelected(item.imageUrl)}
                                                    $index={index}
                                                >
                                                    <S.Figure>
                                                        <S.Img src={item.iconUrl} alt="" />
                                                    </S.Figure>
                                                    <S.Content>
                                                        <Heading tag="h4">{item.title}</Heading>
                                                        <Text>{item.text}</Text>
                                                    </S.Content>
                                                    {selected === item.imageUrl && (
                                                        <S.Outline
                                                            layoutId="outline"
                                                            className="outline"
                                                            initial={false}
                                                            animate={{ borderColor: `yellow` }}
                                                            transition={spring}
                                                        />
                                                    )}
                                                </S.Item>
                                            )
                                        })}
                                    </AnimateSharedLayout>
                                )}
                            </Await>
                        </React.Suspense>
                    </S.Grid>

                    {/*<S.ButtonWrapper>*/}
                    {/*    <Button primary="true" to={`${langForInUrl}/functionalities#functionalities`}>{t('general.seeAllFunctionalities_label')} <HiArrowSmRight/></Button>*/}
                    {/*</S.ButtonWrapper>*/}
                </>
                :
                <>
                    <S.PhoneContainer $expanded={expanded}>
                        <S.PhoneWrapper>
                            <S.Phone>
                                <img src={selected} alt='' />
                            </S.Phone>
                            <React.Suspense fallback={
                                <S.ColorList>
                                    {Array.from({ length: 8 }, (_, index) => (
                                        <S.Item key={index}>
                                            <S.Figure>
                                                <Skeleton
                                                    circle
                                                    height="40px"
                                                    width="40px"
                                                />
                                            </S.Figure>
                                        </S.Item>
                                    ))}
                                </S.ColorList>
                            }>
                                <Await resolve={data}>
                                    {(data) => (
                                        <AnimateSharedLayout>
                                            <S.ColorList>
                                                {data.slice(0, 8).map(item => (
                                                    <S.Item
                                                        key={item.id}
                                                        onClick={() => setSelected(item.imageUrl)}
                                                    >
                                                        <S.Figure>
                                                            <S.Img src={item.iconUrl} alt="" />
                                                        </S.Figure>
                                                        {selected === item.imageUrl && (
                                                            <S.Outline
                                                                layoutId="outline"
                                                                className="outline"
                                                                initial={false}
                                                                animate={{ borderColor: `yellow` }}
                                                                transition={spring}
                                                            />
                                                        )}
                                                    </S.Item>
                                                ))}
                                            </S.ColorList>
                                        </AnimateSharedLayout>
                                    )}
                                </Await>
                            </React.Suspense>
                        </S.PhoneWrapper>
                    </S.PhoneContainer>

                    {/*<S.MobileButtonWrapper>*/}
                    {/*    <Button primary="true" to={`${langForInUrl}/functionalities#functionalities`}>{t('general.seeAllFunctionalities_label')} <HiArrowSmRight/></Button>*/}
                    {/*</S.MobileButtonWrapper>*/}

                    {/*<React.Suspense fallback={<></>}>*/}
                    {/*    <Await resolve={data}>*/}
                    {/*        {(data) => (*/}
                    {/*            <S.MobileItem>*/}
                    {/*                <S.MobileFigure>*/}
                    {/*                    <S.Img src={data.filter(obj => obj.imageUrl === selected)[0]?.iconUrl} alt="" />*/}
                    {/*                </S.MobileFigure>*/}
                    {/*                <div>*/}
                    {/*                    <Heading tag="h4">{data.filter(obj => obj.imageUrl === selected)[0]?.title}</Heading>*/}
                    {/*                    <Text>{data.filter(obj => obj.imageUrl === selected)[0]?.text}</Text>*/}
                    {/*                </div>*/}
                    {/*            </S.MobileItem>*/}
                    {/*        )}*/}
                    {/*    </Await>*/}
                    {/*</React.Suspense>*/}
                </>
            }
        </>
    )
}

const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};

export default FeatureSwitch