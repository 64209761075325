import React from 'react'
import * as S from './TextImage.elements';
import placeholder from '../../assets/img/placeholder.svg';
import { HiArrowSmRight } from 'react-icons/hi';
import Heading from "../Heading/Heading";
import Text, {RichText} from "../Text/Text";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {parseHTMLText} from "../../helpers/parseHTMLText";

const TextImage = (props) => {
    const {langForInUrl} = useCurrentLangCode();

    return (
        <S.TextImageContainer $last={props.last}>
            <S.Flex reverse={props.reverse}>
                <S.Figure>
                    <S.Img src={props.image || placeholder} alt={props.alt || ''} $radius={props.radius} loading={props.eager ? "eager" : "lazy"}/>
                </S.Figure>
                <S.Content>
                    {props.icon && <S.Icon src={props.icon} alt=''/> }
                    {props.title && <Heading tag="h2">{props.title}</Heading> }
                    {props.text && <RichText dangerouslySetInnerHTML={{__html: parseHTMLText(props.text, langForInUrl)}} /> }
                    {props.children && <Text>{props.children}</Text> }
                    {props.object && <RichText>{props.object}</RichText> }
                    {props.buttonUrl && <S.StyledButton primary="true" to={`${langForInUrl}/${props.buttonUrl}`}>{props.buttonText} <HiArrowSmRight/></S.StyledButton>}
                </S.Content>
            </S.Flex>
        </S.TextImageContainer>
    )
}

export default TextImage
