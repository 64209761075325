import {Await} from "react-router-dom";
import functionalityImage from "../../assets/img/blocks/bulletSection/functionalities_image.jpg";
import React from "react";
import Bullet from "../../components/Bullet/Bullet";
import BulletSection from "../../components/BulletSection/BulletSection";

export const DeferredBulletSection = ({data}) => {
    return (
        <React.Suspense>
            <Await resolve={data}>
                {(data) => (
                    <BulletSection title={data[0]?.title} image={functionalityImage} reversed={true} eager={true}>
                        <Bullet
                            heading={data[0]?.bulletOneTitle}
                            description={data[0]?.bulletOneText}
                        />
                        <Bullet
                            heading={data[0]?.bulletTwoTitle}
                            description={data[0]?.bulletTwoText}
                        />
                        <Bullet
                            heading={data[0]?.bulletThreeTitle}
                            description={data[0]?.bulletThreeText}
                        />
                        <Bullet
                            heading={data[0]?.bulletFourTitle}
                            description={data[0]?.bulletFourText}
                        />
                    </BulletSection>
                )}
            </Await>
        </React.Suspense>
    )
}