import React from 'react'
import * as S from './FeatureCard.elements';
import { Text, Heading } from '../../components';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {usePopupLinkContext} from "../../contexts/PopupLinkContext";
import {HiOutlineInformationCircle} from "react-icons/hi";
import {useLocation} from "react-router-dom";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

const FeatureCard = ({data, loading, ...props}) => {
    const {langForInUrl} = useCurrentLangCode();
    const {setViaInternalLink} = usePopupLinkContext();
    const {pathname} = useLocation();

    if(loading) {
        return (
            <S.Card as="article" $skeletonLoading>
                <S.Header>
                    <S.Figure>
                        <Skeleton 
                            circle
                            height="50px"
                            width="50px"
                        />
                    </S.Figure>
                    <S.Title>
                        <Heading tag="h4"><Skeleton width={100} /></Heading>
                    </S.Title>
                </S.Header>
                <Text><Skeleton count={6} /></Text>
            </S.Card>
        )
    }

    return (
        <S.Card as={!Boolean(data?.isPopup) ? 'article' : undefined} to={`${langForInUrl}/functionalities/${data?.slug}`} preventScrollReset={pathname.includes("/functionalities")} onClick={() => setViaInternalLink(true)} $isLink={Boolean(data?.isPopup)} {...props}>
            <S.Header>
                {data?.image &&
                    <S.Figure>
                        <S.Img src={data?.image} alt="" />
                    </S.Figure>
                }
            </S.Header>
            <S.Title>
                <Heading tag="h4">
                    {data?.title}
                    {Boolean(data?.isPopup) && (
                        <S.IconBox>
                            &nbsp;
                            <HiOutlineInformationCircle/>
                        </S.IconBox>
                    )}
                </Heading>
            </S.Title>
            <Text>{data?.text}</Text>
        </S.Card>
    )
}

export default FeatureCard