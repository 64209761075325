import styled, { keyframes } from 'styled-components';
import rings from '../../assets/img/decorations/deco-rings-invert.svg';

export const Flex = styled.section`
    display: flex;
    align-items: center;

    margin: 0 -40px;
    
    @media screen and (max-width: 850px) {
        margin: -40px 0;
        flex-direction: column;
    }
`;

export const TextBox = styled.article`
    flex: 0 1 60%;
    margin: 0 40px;

    @media screen and (max-width: 850px) {
        margin: 40px 0;
        width: 100%;
        text-align: center;
    }

    a {
        margin-top: 20px;
    }
`;

export const CloudBox = styled.div`
    flex: 0 1 40%;
    margin: 0 40px;

    @media screen and (max-width: 850px) {
        margin: 40px 0;
        width: 100%;
    }
`;

export const IntegrationCloud = styled.section`
    background: url(${rings});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    
    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`;

const float = keyframes`
    0% {
        box-shadow: 0 4px 10px 0px rgba(0,0,0,0.2);
		transform: translateY(0px);
    }

    60% {
        box-shadow: 0 25px 10px 0px rgba(0,0,0,0.05);
		transform: translateY(-20px);
    }

    100% {
        box-shadow: 0 4px 10px 0px rgba(0,0,0,0.2);
		transform: translateY(0px);
    }
`;

export const Logo = styled.span`
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
    width: ${props => props.size + '%'};
    height: ${props => props.size + '%'};
    top: ${props => props.top + '%'};
    left: ${props => props.left + '%'};
    animation: ${float} ${props => props.duration + 's'} ease-in-out infinite;
    
    img {
        width: 100%;
        height: 100%;
    }
`;