import Cookies from 'js-cookie';
import {createContext, useContext, useState} from "react";

export const MarketingPopupContext = createContext(null);

export default function MarketingPopupProvider({children}) {
    const hasClosedPopup = Cookies.get('tmsqr_popup');
    const [isOpen, setIsOpen] = useState(!Boolean(hasClosedPopup));

    const openMarketingPopup = () => {
        setIsOpen(true);
    };

    const closeMarketingPopup = () => {
        setIsOpen(false);
    };

    return (
        <MarketingPopupContext.Provider value={{
            isOpen,
            openMarketingPopup,
            closeMarketingPopup,
        }}>
            {children}
        </MarketingPopupContext.Provider>
    )
}

export const useMarketingPopupContext = () => useContext(MarketingPopupContext);