import React from 'react'
import * as S from './InfoBanner.elements';
import { HiArrowSmRight } from 'react-icons/hi';
import { useSize } from 'react-use';
import derrick from '../../assets/img/blocks/infoBanner/derrick.jpg';
import julia from '../../assets/img/blocks/infoBanner/julia.jpg';
import kevin from '../../assets/img/blocks/infoBanner/kevin.jpg';
import Container from "../../components/Container/Container";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

const InfoBanner = ({data}) => {
    const {langForInUrl} = useCurrentLangCode();

    const [btn, {width}] = useSize(
        ({width}) => <Button primary="true" to={`${langForInUrl}/${data[0]?.infoButtonLink}`}>{data[0]?.infoButtonLabel} <HiArrowSmRight/></Button>,
        { width: 100 }
    );

    return (
        <S.InfoBannerContainer>
            <Container>
                <S.Primary btnWidth={width}>
                    <S.Avatars>
                        <S.Avatar className="avatar--one" src={derrick} alt="" />
                        <S.Avatar className="avatar--two" src={julia} alt="" />
                        <S.Avatar className="avatar--three" src={kevin} alt="" />
                    </S.Avatars>
                    <S.Body>
                        <Heading tag="h2">{data[0]?.infoTitle}</Heading>
                        <S.StyledText>{data[0]?.infoText}</S.StyledText>
                    </S.Body>
                </S.Primary>
                <S.Secondary>
                    {btn}
                </S.Secondary>
            </Container>
        </S.InfoBannerContainer>
    )
}

export default InfoBanner
