import React from 'react'
import * as S from './Testimonial.elements';
import { Text, Heading } from '../../components';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {RichText} from "../Text/Text";
import {parseHTMLText} from "../../helpers/parseHTMLText";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Testimonial = ({image, userName, role, text, loading}) => {
    const {langForInUrl} = useCurrentLangCode();
    const {width} = useWindowDimensions();

    if(loading) {
        return (
            <S.Card>
                <S.Header>
                    <S.Figure>
                        <Skeleton circle height="60px" width="60px" />
                    </S.Figure>
                    <Heading tag="h4"><Skeleton width={100} /></Heading>
                </S.Header>
                <Text><Skeleton count={5} /></Text>
                <S.Person><Skeleton width={80} /></S.Person>
            </S.Card>
        )
    }

    return (
        <S.Card $width={width <= 537 ? width : 537}>
            <S.Header>
                <S.Figure>
                    <S.Img src={image} alt="" />
                </S.Figure>
                <Heading tag="h4">{role}</Heading>
            </S.Header>
            <RichText dangerouslySetInnerHTML={{__html: parseHTMLText(text, langForInUrl)}} />
            <S.Person>{userName}</S.Person>
        </S.Card>
    )
}

export default Testimonial
