import styled, {css} from 'styled-components';
import { Container, Button } from '../../components';
import Slider from "react-slick";

export const TextSliderContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: ${props => props.$last ? '240px' : '160px'};

    @media screen and (max-width: 768px) {
        padding-bottom: ${props => props.$last ? '240px' : '80px'};
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

export const SliderBox = styled.div`
    display: flex;
`;

export const ContentBox = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Icon = styled.img`
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
`;

export const StyledButton = styled(Button)`
    align-self: flex-start;
    margin-top: 8px;
`;

export const StyledSlider = styled(Slider)`
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 14px;
    
    .slick-dots {
        bottom: 20px;
        left: 50%;
        width: unset;
        transform: translateX(-50%);

        li {
            display: inline-block;
            transition: all .2s;
            width: 10px;
            height: 10px;

            button {
                transition: all .2s;
                background-color: var(--color-white);
                border-radius: 5px;
                width: 10px;
                height: 10px;

                &:before {
                    content: none;
                }
            }

            &.slick-active {
                transition: all .2s;
                width: 30px;

                button {
                    background-color: var(--color-primary);
                    width: 30px;
                }
            }
        }
    }
    
    .slick-slide {
        border: none;
        overflow: hidden;
    }
    
    div {
        outline: none;
    }
`;

export const Outer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 3 / 2;
    overflow: hidden;
    ${({$center}) => $center && css`margin: auto 0;`}

    @supports not (aspect-ratio: 3 / 2) {
        padding-top: 66.67%;
    }
`;

export const Inner = styled.div`
    display: block;
    width: 100%;
    height: 100%;

    @supports not (aspect-ratio: 3 / 2) {
        position: absolute;
        inset: 0;
    }
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;