import React, { useState } from 'react'
import * as S from './Contact.elements';
import {Container, Heading, CustomerSlider, Text, Input} from '../../components';
import { HiArrowSmRight, HiCheck } from 'react-icons/hi';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import phoneIcon from '../../assets/img/contact/phone.svg';
import mailIcon from '../../assets/img/contact/mail.svg';
import locationIcon from '../../assets/img/contact/location.svg';
import { postRequest } from "../../api/Api";
import Hero from "../../blocks/Hero/Hero";
import {defer, useLoaderData, useRouteLoaderData} from "react-router-dom";
import {FaInstagram} from "react-icons/fa";
import {RiFacebookCircleLine} from "react-icons/ri";
import {PiAndroidLogoBold, PiLinkedinLogoBold} from "react-icons/pi";
import {LuTwitter} from "react-icons/lu";
import {TbBrandApple} from "react-icons/tb";
import TestimonialSlider from "../../blocks/TestimonialSlider/TestimonialSlider";
import CasesOverview from "../../components/CasesOverview/CasesOverview";
import {useTranslation} from "react-i18next";
import {Seo} from "../../components/Seo/Seo";

export async function contactLoader({request, params}) {
    const lang = params?.lang;

    const contactPageData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contact-page${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    const testimonialsData = fetch(`${process.env.REACT_APP_API_BASE_URL}/testimonials${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    return defer({contactPageData, testimonialsData});
}

const Contact = () => {
    const {t} = useTranslation();
    const {data} = useRouteLoaderData("root");
    const {contactPageData, testimonialsData} = useLoaderData();
    const [isFormSent, setIsFormSent] = useState(false);
    
    const handleIsFormSent = () => {
        setIsFormSent(true);
    }

    return (
        <>
            <Seo
                title={t('navbar.contact_label')}
                description={contactPageData[0]?.metaDescription}
                slug="/contact-us"
            />

            <Hero
                type="overlap"
                heading={contactPageData[0]?.title}
                text={contactPageData[0]?.text}
            />

            <S.ContactContainer>
                <Container>
                    <S.Flex>
                        <S.Info>
                            <Heading tag="h2">{contactPageData[0]?.helpTitle}</Heading>
                            <Text>{contactPageData[0]?.helpText}</Text>

                            <S.List>
                                <S.Item>
                                    <img src={phoneIcon} alt="" />
                                    <Heading tag="h4">+31 71 2034054</Heading>
                                </S.Item>
                                <S.Item>
                                    <img src={mailIcon} alt="" />
                                    <Heading tag="h4">info@tmsqr.com</Heading>
                                </S.Item>
                                <S.Item>
                                    <img src={locationIcon} alt="" />
                                    <Heading tag="h4">
                                        Dorus Rijkersweg 15<br/>2315 WC Leiden<br/>The Netherlands
                                    </Heading>
                                </S.Item>
                            </S.List>
                            <S.IconList>
                                <a href="https://www.instagram.com/tmsqr.app/" target="_blank" rel="noreferrer"><FaInstagram /></a>
                                <a href="https://www.facebook.com/TMSQR/" target="_blank" rel="noreferrer"><RiFacebookCircleLine /></a>
                                <a href="https://www.linkedin.com/company/tmsqr/" target="_blank" rel="noreferrer"><PiLinkedinLogoBold /></a>
                                <a href="https://twitter.com/tmsqr_app" target="_blank" rel="noreferrer"><LuTwitter /></a>
                                <a href="https://apps.apple.com/nl/app/timesquare/id731062580" target="_blank" rel="noreferrer"><TbBrandApple /></a>
                                <a href="https://play.google.com/store/apps/details?id=com.zooma.timesquare" target="_blank" rel="noreferrer"><PiAndroidLogoBold /></a>
                            </S.IconList>
                        </S.Info>
                        <S.Form>
                            {!isFormSent ? (
                                <>
                                    <Heading tag="h3" smaller="true">{t('contact.title')}</Heading>

                                    <Formik
                                        initialValues={{
                                            name: "",
                                            companyName: "",
                                            email: "",
                                            text: "",
                                        }}
                                        validationSchema={Yup.object({
                                            name: Yup.string()
                                                .max(50, t('contact.error_maximum_50'))
                                                .required(t('contact.error_required_name')),
                                            companyName: Yup.string().max(50, t('contact.error_maximum_50')),
                                            email: Yup.string()
                                                .email(t('contact.error_invalid_email'))
                                                .required(t('contact.error_required_email')),
                                            text: Yup.string()
                                                .max(2000, t('contact.error_maximum_2000'))
                                                .required(t('contact.error_required_message')),
                                        })}
                                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                                            try {
                                                postRequest("contactform", JSON.stringify(values, null, 2));
                                            } catch (error) {
                                                console.error(t('contact.error'));
                                            }

                                            handleIsFormSent();

                                            resetForm({});
                                            setSubmitting(false);
                                        }}
                                    >
                                        <Form>
                                            <S.DoubleInput>
                                                <S.InputSet>
                                                    <Input
                                                        label={t('contact.name_label')}
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        placeholder={t('contact.name_placeholder')}
                                                    />
                                                </S.InputSet>
                                                <S.InputSet>
                                                    <Input
                                                        label={t('contact.festival_label')}
                                                        id="companyName"
                                                        name="companyName"
                                                        type="text"
                                                        placeholder={t('contact.festival_placeholder')}
                                                    />
                                                </S.InputSet>
                                            </S.DoubleInput>
                                            <Input
                                                label={t('contact.email_label')}
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder={t('contact.email_placeholder')}
                                            />
                                            <Input
                                                as="textarea"
                                                textarea="true"
                                                label={t('contact.message_label')}
                                                id="text"
                                                name="text"
                                                type="text"
                                                placeholder={t('contact.message_placeholder')}
                                                rows="7"
                                            />

                                            <S.StyledButton as="button" primary="true" type="submit">
                                                {t('contact.send_button')} <HiArrowSmRight />
                                            </S.StyledButton>
                                        </Form>
                                    </Formik>
                                </>
                            ) : (
                                <S.Submitted>
                                    <S.Check>
                                        <HiCheck />
                                    </S.Check>
                                    <Heading tag="h2">{t('contact.feedback_title')}</Heading>
                                    <Text>{t('contact.feedback_text')}</Text>
                                </S.Submitted>
                            )}
                        </S.Form>
                    </S.Flex>
                </Container>
            </S.ContactContainer>

            <CustomerSlider data={contactPageData}/>

            <CasesOverview
                data={data}
                title={contactPageData[0]?.casesTitle}
                description={contactPageData[0]?.casesText}
                buttonLabel={contactPageData[0]?.casesButtonLabel}
                buttonLink={contactPageData[0]?.casesButtonLink}
                maxLength={true}
                exclude="bottom"
            />

            <TestimonialSlider data={testimonialsData} />
        </>
    );
}

export default Contact
