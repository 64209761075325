import * as S from "./PricingPackages.elements";
import Heading from "../Heading/Heading";
import Text, {RichText} from "../Text/Text";
import Button from "../Button/Button";
import {HiArrowSmDown, HiArrowSmRight} from "react-icons/hi";
import React, {useEffect, useState} from "react";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {useTranslation} from "react-i18next";
import {parseHTMLText} from "../../helpers/parseHTMLText";
import Container from "../Container/Container";
import {ContainerSmall, Wrapper} from "../index";
import {ScrollSync, ScrollSyncPane} from "react-scroll-sync";
import {Cell, Row, StickyTable, StickyTableWrapper} from "../PricingTable/PricingTable.elements";
import {PricingTable} from "../PricingTable/PricingTable";
import {useChangeNavbarOnScroll} from "../../hooks/useChangeNavbarOnScroll";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const PricingPackages = ({data}) => {
    const {t} = useTranslation();
    const {langForInUrl} = useCurrentLangCode();

    // Handle the different switches and state if table is expanded
    const hasSwitch = data?.pricingSwitches?.length > 1;
    const [activeSwitchIndex, setActiveSwitchIndex] = useState(0);
    const [isTableOpen, setIsTableOpen] = useState(false);

    const handlePricingTableButtonClick = (e) => {
        setIsTableOpen(!isTableOpen);
        e.target.blur();
    }

    // Observer to check if sticky table is attached to apply different styles
    const {scrolled} = useChangeNavbarOnScroll();
    const {width} = useWindowDimensions();
    const isMobile = width < 1024;

    useEffect(() => {
        const el = document.querySelector(".element");
        const observer = new IntersectionObserver(([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1), {
            rootMargin: isMobile ? '-78px 0px 0px 0px' : `-${scrolled ? 84 : 96}px 0px 0px 0px`,
            threshold: [1] }
        );
        observer.observe(el);

        return () => observer.disconnect();
    }, [scrolled, isTableOpen, isMobile]);

    return (
        <>
            <S.PricingContainer $hasSwitch={hasSwitch}>
                {hasSwitch &&
                    <Container>
                        <S.PricingSwitchWrapper>

                            <S.SwitchOuter>
                                {data?.pricingSwitches?.map((item, index) =>
                                    <S.SwitchItem key={item.id} $active={index === activeSwitchIndex} onClick={() => setActiveSwitchIndex(index)}>{item.title}</S.SwitchItem>
                                )}
                            </S.SwitchOuter>

                        </S.PricingSwitchWrapper>
                    </Container>
                }

                <S.StyledContainerMedium>
                    <S.Packages $columns={data?.pricingSwitches[activeSwitchIndex]?.packages?.length}>
                        {data?.pricingSwitches[activeSwitchIndex]?.packages?.map(item => (
                            <S.PackageCard key={item.id} $isPopular={Boolean(item.highlight)}>
                                <S.PackageHeader>
                                    {item.icon &&
                                        <S.PackageFigure>
                                            <S.PackageImage src={item.icon} alt="" />
                                        </S.PackageFigure>
                                    }

                                    {Boolean(item.highlight) && <S.PackageLabel>{t('pricing.popular_label')}</S.PackageLabel>}
                                </S.PackageHeader>
                                <Heading tag="h3" $noMargin white={Boolean(item.highlight)}>{item.title}</Heading>
                                <Text $noMargin>{item.text}</Text>
                                <S.PackageWrapper>
                                    <S.PriceBox $isPopular={Boolean(item.highlight)} $addBorder={Boolean(item.setupPrice)}>
                                        <S.Price>{item.price}</S.Price>
                                        <div>
                                            <S.VariablePrice $noMargin dangerouslySetInnerHTML={{__html: item.priceSubtitle1}} />
                                            <S.VariablePrice $noMargin>{item.priceSubtitle2}</S.VariablePrice>
                                        </div>
                                    </S.PriceBox>
                                    {item.setupPrice &&
                                        <Text $noMargin>{item.setupPrice}</Text>
                                    }
                                    <S.PackageButtonWrapper>
                                        <Button to={`${langForInUrl}/${item.buttonLink}`} primary="true" $primaryDarker={Boolean(item.highlight)}>{item.buttonLabel} <HiArrowSmRight/></Button>
                                    </S.PackageButtonWrapper>
                                </S.PackageWrapper>
                            </S.PackageCard>
                        ))}
                    </S.Packages>

                    {(data?.tableExtraTextOne || data?.tableExtraTextTwo) &&
                        <S.TextGrid>
                            <RichText dangerouslySetInnerHTML={{__html: parseHTMLText(data?.tableExtraTextOne, langForInUrl)}} />
                            <RichText dangerouslySetInnerHTML={{__html: parseHTMLText(data?.tableExtraTextTwo, langForInUrl)}} />
                        </S.TextGrid>
                    }
                </S.StyledContainerMedium>
            </S.PricingContainer>

            {data?.pricingSwitches[activeSwitchIndex]?.packages?.length &&
                <Wrapper>
                    <ContainerSmall>
                        <Heading tag="h2">{data?.tableTitle}</Heading>
                    </ContainerSmall>

                    <S.TableContainer $isTableOpen={isTableOpen}>
                        <ScrollSync>
                            <>
                                <StickyTableWrapper className="element" $isTableOpen={isTableOpen} $scrolled={scrolled}>
                                    <ScrollSyncPane group="horizontal">
                                        <StickyTable>
                                            <Row $cells={data?.pricingSwitches[activeSwitchIndex]?.packages?.length + 1}>
                                                <Cell $rowTitle $noMargin></Cell>
                                                {data?.pricingSwitches[activeSwitchIndex]?.packages?.map(item => (
                                                    <Cell key={item.title}>
                                                        <Heading tag="h3" $noMargin>{item.title}</Heading>
                                                    </Cell>
                                                ))}
                                            </Row>
                                        </StickyTable>
                                    </ScrollSyncPane>
                                </StickyTableWrapper>

                                {data?.pricingSwitches[activeSwitchIndex]?.tables?.map(table => (
                                    <PricingTable key={table.id} table={table} />
                                ))}
                            </>
                        </ScrollSync>
                    </S.TableContainer>
                    <ContainerSmall>
                        {isTableOpen &&
                            <S.TableOpenTextWrapper>
                                <RichText dangerouslySetInnerHTML={{__html: parseHTMLText(data?.tableText, langForInUrl)}} />
                            </S.TableOpenTextWrapper>
                        }
                        <Button primary="true" as="button" onClick={handlePricingTableButtonClick} $up={isTableOpen}>{isTableOpen ? <>{data?.tableCloseButtonLabel} <HiArrowSmRight/></> : <>{data?.tableOpenButtonLabel} <HiArrowSmDown/></>}</Button>
                    </ContainerSmall>
                </Wrapper>
            }
        </>
    );
}