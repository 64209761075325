import React, { useState, useRef } from "react";
import Chevron from "./Chevron";
import * as S from './Accordion.elements';
import { Heading, Text } from '../../components';
// import "./Accordion.css";

const Accordion = (props) => {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(setActive === "active" ? "0px" : `${content.current.scrollHeight}px`);
        setRotateState(setActive === "active" ? "accordion__icon" : "accordion__icon rotate");
    }

    return (
        <S.Section>
            <S.Accordion className={`accordion ${setActive}`} onClick={toggleAccordion}>
                <Heading tag="h4">{props.title}</Heading>
                <Chevron className={`${setRotate}`} width={10} fill={"#171717"} />
            </S.Accordion>
            <S.Content ref={content} style={{ maxHeight: `${setHeight}` }} className="accordion__content">
                <Text>{props.content}</Text>
            </S.Content>
        </S.Section>
    );
}

export default Accordion
