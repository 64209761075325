import styled from "styled-components";
import {createPortal} from "react-dom";
import {Transition} from "react-transition-group";
import React, {useEffect, useRef, useState} from "react";
import {usePopupLinkContext} from "../../contexts/PopupLinkContext";
import {useLoaderData, useNavigate} from "react-router-dom";
import Text from '../../components/Text/Text';
import Heading from "../../components/Heading/Heading";
import {HiX} from 'react-icons/hi';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as S from "../../components/CaseCard/CaseCard.elements";
import {ReactComponent as TMSQR} from "../../assets/img/cases/tmsqr.svg";
import {ReactComponent as Apple} from "../../assets/img/cases/apple.svg";
import {ReactComponent as Android} from "../../assets/img/cases/android.svg";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {Seo} from "../../components/Seo/Seo";
import {useTranslation} from "react-i18next";

const StyledCasePopup = styled.div`
    pointer-events: none;
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    z-index: 102;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: 1; }
    &.entered { opacity: 1; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

const PopupInner = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    max-width: 540px;
    max-height: 100%;
    overflow-x: auto;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 17px;
`;

const CloseButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 999;
    width: 24px;
    height: 24px;
    
    svg {
        color: var(--color-black);
        font-size: 2.4rem;
    }
`;

const Outer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 19;

    @supports not (aspect-ratio: 16 / 19) {
        overflow: hidden;
        padding-top: 118.75%;
    }
`;

const Inner = styled.div`
    display: block;
    width: 100%;
    height: 100%;

    @supports not (aspect-ratio: 16 / 19) {
        position: absolute;
        inset: 0;
    }
`;

const StyledSlider = styled(Slider)`
    width: 100%;
    height: 100%;
    overflow: hidden;
    
    .slick-dots {
        bottom: 20px;
        left: 50%;
        width: unset;
        transform: translateX(-50%);

        li {
            display: inline-block;
            transition: all .2s;
            width: 8px;
            height: 8px;

            button {
                transition: all .2s;
                background-color: #D7D7D7;
                border-radius: 7px;
                width: 8px;
                height: 8px;

                &:before {
                    content: none;
                }
            }

            &.slick-active {
                transition: all .2s;
                width: 30px;

                button {
                    background-color: var(--color-text);
                    width: 30px;
                }
            }
        }
    }
    
    .slick-slide {
        border: none;
        overflow: hidden;
    }
    
    div {
        outline: none;
    }
`;

const Slide = styled.div`
    width: 100%;
    height: 100%;
    
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const Content = styled.div`
    padding: 22px 30px;
`;

const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-black);
    height: 100%;
    width: 100%;
    z-index: 101;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: .5; }
    &.entered { opacity: .5; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

export async function casePopupLoader({request, params}) {
    const lang = params?.lang;

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers/${params?.slug}${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page is not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}

const ConditionalWrap = ({condition, wrap, fallback, children}) => {
    return condition ? wrap(children) : fallback ? fallback(children) : children;
}

export const CasePopup = () => {
    const {langForInUrl} = useCurrentLangCode();
    const {t} = useTranslation();
    const {data} = useLoaderData();
    const {viaInternalLink} = usePopupLinkContext();
    const navigate = useNavigate();

    const casePopupRef = useRef(null);
    const underlayNodeRef = useRef(null);
    const touchActionStyle = useRef("");
    const overflowStyle = useRef("");
    const [isOpen, setIsOpen] = useState(!viaInternalLink);

    const openPopupLink = () => {
        setIsOpen(true);
        touchActionStyle.current = document.body.style.touchAction;
        document.body.style.touchAction = "none";
        overflowStyle.current = document.body.style.overflow;
        document.body.style.overflow = "hidden";
    };

    const closePopupLink = () => {
        setIsOpen(false);
        document.body.style.touchAction = touchActionStyle.current;
        document.body.style.overflow = overflowStyle.current;
    };

    useEffect(() => {
        openPopupLink();

        return () => closePopupLink();
    }, []);

    let closeTimeout;
    const handleClosePopupLink = () => {
        clearTimeout(closeTimeout);

        closePopupLink();

        closeTimeout = setTimeout(() => {
            navigate(`${langForInUrl}/cases`, { preventScrollReset: true });
        }, 200);
    }

    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
    };

    return createPortal(
        <>
            <Seo
                title={data?.title}
                description={data?.text}
                slug={`/cases/${data?.slug}`}
            />

            <Transition nodeRef={casePopupRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <StyledCasePopup ref={casePopupRef} className={state}>
                        <PopupInner>
                            <CloseButton onClick={handleClosePopupLink}>
                                <HiX />
                            </CloseButton>
                            {data?.slides?.length > 0 &&
                                <Outer>
                                    <Inner>
                                        <StyledSlider {...settings}>
                                            {data.slides.map(item => {
                                                if(!item.imageUrl) return null;

                                                return (
                                                    <Slide key={item.id}>
                                                        <img src={item.imageUrl} alt={item.alt ?? ""} />
                                                    </Slide>
                                                );
                                            })}
                                        </StyledSlider>
                                    </Inner>
                                </Outer>
                            }
                            <Content>
                                <Heading tag="h3">{data?.title}</Heading>
                                <Text>{data?.text}</Text>

                                <S.Download>
                                    <S.DownloadText>{t('general.downloadTheApp_label')}</S.DownloadText>

                                    <S.DownloadList>
                                        <ConditionalWrap condition={Boolean(data.tmsqrLink)} wrap={children => <a href={data.tmsqrLink} target="_blank" rel="noreferrer">{children}</a>}>
                                            <S.Icon as={TMSQR} $active={Boolean(data.tmsqrLink)} />
                                        </ConditionalWrap>
                                        <ConditionalWrap condition={Boolean(data.appStoreLink)} wrap={children => <a href={data.appStoreLink} target="_blank" rel="noreferrer">{children}</a>}>
                                            <S.Icon as={Apple} $active={Boolean(data.appStoreLink)} />
                                        </ConditionalWrap>
                                        <ConditionalWrap condition={Boolean(data.playStoreLink)} wrap={children => <a href={data.playStoreLink} target="_blank" rel="noreferrer">{children}</a>}>
                                            <S.Icon as={Android} $active={Boolean(data.playStoreLink)} />
                                        </ConditionalWrap>
                                    </S.DownloadList>
                                </S.Download>
                            </Content>
                        </PopupInner>
                    </StyledCasePopup>
                )}
            </Transition>

            <Transition nodeRef={underlayNodeRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <Underlay ref={underlayNodeRef} className={state} onClick={handleClosePopupLink} />
                )}
            </Transition>
        </>, document.body
    );
}