import styled, { keyframes } from 'styled-components';

export const LoadingContainer = styled.section`
    width: 100%;
    height: 100vh;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-background);
    z-index: 99;
`;

const spinner = keyframes`
    0%, 20%, 80%, 100% {
        transform: scale(1.5);
    }
    50% {
        transform: scale(1);
    }
`;

export const Spinner = styled.div`
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;

    span {
        position: absolute;
        width: 10px;
        height: 10px;
        background: var(--color-primary);
        border-radius: 50% 50%;
        animation: ${spinner} .9s linear infinite;

        &:nth-child(1) {
            animation-delay: 0s;
            top: 30px;
            left: 30px;
        }

        &:nth-child(2) {
            animation-delay: -0.1s;
            top: 30px;
            left: 60px;
        }

        &:nth-child(3) {
            animation-delay: -0.2s;
            top: 60px;
            left: 30px;
        }

        &:nth-child(4) {
            animation-delay: -0.3s;
            top: 60px;
            left: 60px;
        }
    }
`;

const loadingtext = keyframes`
    0%, 100%   { content: ''; }
    12.5%, 75%  { content: '.'; }
    25%, 62.5%  { content: '..'; }
    50%  { content: '...'; }
`;

export const LoadingText = styled.p`
    font-size: 1.6rem;
    color: var(--color-heading);
    font-weight: 600;

    &:after {
        display: inline-block;
        animation: ${loadingtext} 4s infinite;
        content: '';
    }
`;