import styled, {keyframes} from 'styled-components';
import Cookies from 'js-cookie';
import React, {useRef} from 'react';
import {Transition} from 'react-transition-group';
import {createPortal} from 'react-dom';
import background from '../assets/img/pricing/background.svg';
import {HiArrowSmRight, HiX} from 'react-icons/hi';
import {useMarketingPopupContext} from '../contexts/MarketingPopupContext';
import Heading from './Heading/Heading';
import Text from './Text/Text';
import Button from './Button/Button';

const pattern = keyframes`
    100%{
        background-position: 2000px -2000px;
    }
`;

const StyledMarketingPopup = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 550px;
    aspect-ratio: 1 / 1;
    background: url(${background}), rgb(246, 246, 246);
    animation: ${pattern} 180s linear infinite;
    border-radius: 50%;
    z-index: 106;
    opacity: 0;
    overflow-x: auto;
    max-height: 100%;
    display: flex;
    transition: opacity .2s ease;

    &.entering { opacity: 1; }
    &.entered { opacity: 1; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }

    @supports not (aspect-ratio: 1 / 1) {
        overflow: hidden;
        padding-top: 100%;
    }
`;

const Inner = styled.div`
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    object-fit: cover;
    border: none;
    padding: 50px;

    @supports not (aspect-ratio: 1 / 1) {
        position: absolute;
        inset: 0;
    }
    
    h1 { 
        text-align: center;
        max-width: 440px;
        overflow-wrap: anywhere;
    }

    p {
        text-align: center;
        max-width: 500px;
        overflow-wrap: anywhere;
    }
    
    @media screen and (max-width: 525px) {
        h1 {
            font-size: 2.5rem;
        }
    }

    @media screen and (max-width: 425px) {
        h1 {
            font-size: 2rem;
        }
    }
`;

const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-black);
    height: 100%;
    width: 100%;
    z-index: 105;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: .5; }
    &.entered { opacity: .5; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const MarketingPopup = ({data}) => {
    const cookieNodeRef = useRef(null);
    const underlayNodeRef = useRef(null);
    const {isOpen, closeMarketingPopup} = useMarketingPopupContext();

    const handleClosePopup = () => {
        Cookies.set('tmsqr_popup', 1, {expires: 31, secure: true});
        closeMarketingPopup();
    }

    if(!data.popupTitle && !data.popupText) return null;

    return createPortal(
        <>
            <Transition nodeRef={cookieNodeRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <StyledMarketingPopup ref={cookieNodeRef} className={state}>
                        <Inner>
                            <Heading tag="h1" textOnSurface $noMargin>{data.popupTitle}</Heading>
                            <Text textOnSurface>{data.popupText}</Text>
                            <Buttons>
                                {data.popupButton && <Button primary="true" as="button" onClick={handleClosePopup}>{data.popupButton} <HiArrowSmRight /></Button>}
                                {data.popupCloseButton && <Button surface="true" as="button" onClick={handleClosePopup} $chevron>{data.popupCloseButton} <HiX /></Button>}
                            </Buttons>
                        </Inner>
                    </StyledMarketingPopup>
                )}
            </Transition>

            <Transition nodeRef={underlayNodeRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <Underlay ref={underlayNodeRef} className={state} onClick={handleClosePopup} />
                )}
            </Transition>
        </>, document.body
    );
}

export default MarketingPopup;