import * as S from "./PricingTable.elements";
import Heading from "../Heading/Heading";
import {ScrollSyncPane} from "react-scroll-sync";
import {HiChevronRight, HiOutlineInformationCircle} from "react-icons/hi";
import React, {useState, useRef, useMemo, useEffect} from "react";

export const PricingTable = ({table}) => {
    return (
        <S.StyledPricingTable>
            <S.HeadingWrapper>
                <Heading tag="h3" $noMargin>{table.title}</Heading>
            </S.HeadingWrapper>
            <ScrollSyncPane group="horizontal">
                <S.Table>
                    {table.rows.map((row, index) => {
                        const isOddRow = index % 2 === 0;
                        const hasFeature = Boolean(row.feature) && Boolean(row.feature.isPopup);
                        const isCategory = row?.type === "category";

                        if(isCategory) {
                            return (
                                <CategoryRowComponent key={row.id} row={row} />
                            )
                        }

                        return (
                            <S.Row key={row.id} $cells={row.cells.length + 1} $isOdd={isOddRow} $indent>
                                <S.Cell $rowTitle $isOdd={isOddRow} $indent>
                                    <S.StyledLink as={!hasFeature ? "p" : undefined} to={hasFeature ? `${row.feature.slug}` : undefined} preventScrollReset>
                                        {row.title}
                                        {hasFeature && (
                                            <S.IconBox>
                                                &nbsp;
                                                <HiOutlineInformationCircle/>
                                            </S.IconBox>
                                        )}
                                    </S.StyledLink>
                                    {row.subtitle && <S.Subtitle $noMargin>{row.subtitle}</S.Subtitle>}
                                </S.Cell>
                                {row.cells.map(cell => (
                                    <S.Cell key={cell.id} $isOdd={isOddRow}>{cell.type === "check" ? <S.StyledCheck /> : <S.CellText $noMargin dangerouslySetInnerHTML={{__html: cell.text}} />}</S.Cell>
                                ))}
                            </S.Row>
                        )
                    })}
                </S.Table>
            </ScrollSyncPane>
        </S.StyledPricingTable>
    );
}

const CategoryRowComponent = ({row}) => {
    const [open, setOpen] = useState(row?.defaultOpen ?? false);
    const [height, setHeight] = useState(false);
    const content = useRef(null);

    let timeout;
    function toggleAccordion(status) {
        setOpen(status === "opening");

        if(status === "opening") {
            content.current.style.overflow = "unset";
            setHeight(`${content.current.scrollHeight}px`);
        }

        // Set overflow after a timeout when closing, because the row title is sticky on mobile and sticky elems can't be placed in overflow container
        if(status === "closing") {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                content.current.style.overflow = "hidden";
            }, 200)

            setHeight("0px");
        }
    }

    // Check if there is any cell (with type Check) in a column, to display a check when closed
    const areAnyCellsCheckType = useMemo(() => {
        const cellTypeChecks = [];

        for (let i = 0; i < 3; i++) {
            const hasCheck = row?.subRows?.some(subRow => subRow?.cells?.[i]?.type === 'check');
            cellTypeChecks.push(hasCheck);
        }

        return cellTypeChecks;
    }, [row]);

    // If "defaultOpen" it should add the correct maxHeight
    useEffect(() => {
        if (content.current) {
            content.current.style.overflow = open ? "unset" : "hidden";
            setHeight(open ? `${content.current.scrollHeight}px` : "0px");

        }
        //eslint-disable-next-line
    }, [content])

    return (
        <S.CategoryRowWrapper $open={open} $cells={4}>
            <S.CategoryRow key={row.id} $cells={4} onClick={() => toggleAccordion(open ? "closing" : "opening")} $indent>
                <S.Cell $rowTitle $hasSubRows $indent>
                    <S.Category $open={open}><HiChevronRight /> {row.title}</S.Category>
                </S.Cell>
                {areAnyCellsCheckType.map((isAllCheck, index) => (
                    <S.Cell key={index}>
                        {isAllCheck && <S.StyledCheck $hide={open} />}
                    </S.Cell>
                ))}
            </S.CategoryRow>

            <S.ExpandedContent ref={content} $height={height} $cells={4} $open={open}>
                {row?.subRows?.map((subRow, index) => {
                    const isOddRow = index % 2 === 0;
                    const hasFeature = Boolean(subRow.feature) && Boolean(subRow.feature.isPopup);

                    return (
                        <S.Row key={subRow.id} $cells={subRow.cells.length >= 3 ? subRow.cells.length + 1 : 4} $isOdd={isOddRow} $isNested>
                            <S.Cell $rowTitle $isOdd={isOddRow}>
                                <S.StyledLink as={!hasFeature ? "p" : undefined} to={hasFeature ? `${subRow.feature.slug}` : undefined} preventScrollReset>
                                    {subRow.title} 
                                    {hasFeature && (
                                        <S.IconBox>
                                            &nbsp;
                                            <HiOutlineInformationCircle/>
                                        </S.IconBox>
                                    )}
                                </S.StyledLink>
                                {subRow.subtitle && <S.Subtitle $noMargin>{subRow.subtitle}</S.Subtitle>}
                            </S.Cell>
                            {subRow.cells.map(cell => (
                                <S.Cell key={cell.id} $isOdd={isOddRow}>{cell.type === "check" ? <S.StyledCheck /> : <S.CellText $noMargin dangerouslySetInnerHTML={{__html: cell.text}} />}</S.Cell>
                            ))}
                            {/* Temp fix that there might be 0 cells in a sub row */}
                            {subRow?.cells?.length === 0 &&
                                <>
                                    <S.Cell $isOdd={isOddRow} />
                                    <S.Cell $isOdd={isOddRow} />
                                    <S.Cell $isOdd={isOddRow} />
                                </>
                            }
                        </S.Row>
                    );
                })}
            </S.ExpandedContent>
        </S.CategoryRowWrapper>
    );
}