import styled, {css} from 'styled-components';
import {Link} from "react-router-dom";
import Text from '../../components/Text/Text';

export const StyledCaseCard = styled(Link)`
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    height: 100%;
    transition: .2s ease box-shadow;
    
    ${({$isLink}) => $isLink && css`
        &:hover {
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        }
    `}
`;

export const OuterImage = styled.div`
    flex-shrink: 0;
    position: relative;
    width: 100%;
    aspect-ratio: 15 / 8;
    overflow: hidden;

    @supports not (aspect-ratio: 15 / 8) {
        padding-top: 53.34%;
    }
`;

export const InnerImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;

    @supports not (aspect-ratio: 15 / 8) {
        position: absolute;
        inset: 0;
    }
`;

export const InnerCaseCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    height: 100%;
`;

export const Title = styled.div`
    svg {
        transform: rotate(-45deg);
        width: 24px;
        height: 24px;
        vertical-align: middle;
        transition: transform .2s ease;
    }

    ${StyledCaseCard}:hover &, ${StyledCaseCard}:focus & {
        svg {
            transform: rotate(0);
        }
    }
`;

export const SkeletonFigure = styled.div`
    width: 100%;
    
    .react-loading-skeleton {
        display: block;
    }
`;

export const Download = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    margin-top: auto;
    padding-top: 12px;
    align-items: center;
`;

export const DownloadText = styled(Text)`
    font-family: var(--font-button);
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    color: var(--color-black);
`;

export const DownloadList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    
    a {
        display: block;
    }
`;

export const Icon = styled.span`
    width: 24px;
    height: 24px;
    
    ${({$active}) => $active && css`
        path { fill: var(--color-primary); }
    `}
`;
