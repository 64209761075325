import React, { useEffect } from 'react'
import * as S from './Hero.elements';
import { useViewportScroll, motion, useTransform } from "framer-motion";
import hexagon from '../../assets/img/decorations/deco-hexagon.svg';
import Heading from "../../components/Heading/Heading";
import Text from "../../components/Text/Text";
import Container from "../../components/Container/Container";

const Hero = ({children, superTitle, heading, text, image, alt, type, ...props}) => {
    const { scrollY } = useViewportScroll();
    // when scrollY falls between 0-400 return a value within the range of 0-100, so when scrollY = 200 output = 50
    const y1 = useTransform(scrollY, [0, 400], [0, 100]);

    // Transform elements on mouse move and reset on mouse out
    const transformElements = (e) => {
        document.querySelectorAll('.layer').forEach(layer => {
            const windowWidth = window.innerWidth / (10 * layer.getAttribute('data-spread'));
            const windowHeight = window.innerHeight / (10 * layer.getAttribute('data-spread')) ;
            const mouseX = e.clientX / windowWidth;
            const mouseY = e.clientY / windowHeight;
            layer.style.transform = `translate3d(-${mouseX}%, -${mouseY}%, 0)`;
        })
    }

    useEffect(() => {
        window.addEventListener('mousemove', transformElements);
        return () => window.removeEventListener('mousemove', transformElements);
    }, []);

    return (
        <>
            <S.Hero type={type}>

                <S.HeroText type={type} {...props}>
                    {superTitle && superTitle}
                    <Heading tag="h1">{heading}</Heading>
                    <Text $supportPreWrap>{text}</Text>
                </S.HeroText>

                { (type === 'functionality') && 
                    <Container center>
                        <motion.figure style={{y: y1, x: 0}}>
                            <S.PhoneImg src={image} alt={alt} draggable="false" />
                        </motion.figure>
                    </Container>
                }

                { (type === 'functionality') &&
                    <S.FloatingElements>
                        <S.BoundingBox>
                            {children}
                        </S.BoundingBox>
                    </S.FloatingElements> 
                }

                <S.DecorationHexagon type={type} src={hexagon} alt="" draggable="false" {...props} />

                { (type !== 'home') &&
                    <svg viewBox="0 0 500 40" preserveAspectRatio="none">
                        <path d="M0 40 0 0Q250 40 500 0L500 40Z" fill="#fcfcfc" />
                    </svg>
                }
            </S.Hero>

            { (type === 'overlap' || type === 'customer') &&
                <>
                    {children}
                </>
            }
        </>
    )
}

export default Hero