import styled, { css } from 'styled-components';
import { Link } from "react-router-dom";

const primaryStyles = css`
    background-color: var(--color-primary);
    background-size: 0 100%;
    background-image: linear-gradient(to left, var(--color-primary-60), var(--color-primary-60));
    background-repeat: no-repeat;
    padding: 12px 24px;
    color: var(--color-button);

    &:hover, &:focus {
        background-image: linear-gradient(to left, var(--color-primary-60), var(--color-primary-60));
        background-size: 100% 100%;
    }
`;

const onPrimaryStyles = css`
    ${primaryStyles};
    background-color: var(--color-button-on-primary);
    background-image: linear-gradient(to left, var(--color-primary-60), var(--color-primary-60));
    color: var(--color-button);

    &:hover, &:focus {
        background-image: linear-gradient(to left, var(--color-primary-60), var(--color-primary-60));
    }
`;

const secondaryStyles = css`
    padding: 12px 0;
`;

const surfaceStyles = css`
    color: var(--color-black);
    background-size: 0 100%;
    background-image: linear-gradient(to left, rgba(0,0,0,.02), rgba(0,0,0,.02));
    background-repeat: no-repeat;
    padding: 12px 24px;

    &:hover, &:focus {
        background-image: linear-gradient(to left, rgba(0,0,0,.02), rgba(0,0,0,.02));
        background-size: 100% 100%;
    }
`;

const primaryDarkerStyles = css`
    background-color: var(--color-button-on-primary);
    background-size: 0 100%;
    background-image: linear-gradient(to left, var(--color-button-on-primary-hover), var(--color-button-on-primary-hover));
    background-repeat: no-repeat;
    padding: 12px 24px;

    &:hover, &:focus {
        background-image: linear-gradient(to left, var(--color-button-on-primary-hover), var(--color-button-on-primary-hover));
        background-size: 100% 100%;
    }
`;

const Button = styled(Link)`
    cursor: pointer;
    position: relative;
    font-family: var(--font-button);
    font-weight: var(--fw-bold);
    line-height: 1.2;
    border-radius: 24px;
    font-size: var(--fs-button);
    text-align: center;
    display: inline-block;
    transition: transform .2s ease, background-color .2s ease, background-size .2s ease;

    ${props => props.primary && primaryStyles }
    ${props => props.$onPrimary && onPrimaryStyles }
    ${props => props.secondary && secondaryStyles }
    ${props => props.surface && surfaceStyles }
    ${props => props.$primaryDarker && primaryDarkerStyles }

    &:active {
        transform: translateY(3px)
    }
    
    svg {
        ${({$chevron}) => !$chevron && css`transform: rotate(-45deg);`};
        width: 24px;
        height: 24px;
        vertical-align: middle;
        transition: transform .2s ease;
    }
    
    &:hover, &:focus {

        svg {
            transform: rotate(${({$up}) => $up ? '-90deg' : '0'});
        }
    }
`;

export default Button;