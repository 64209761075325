import styled, { css } from 'styled-components';
// import { Link } from 'react-router-dom';
import { Container, Button } from '../../components';

export const TextImageContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: ${props => props.$last ? '240px' : '80px'};

    @media screen and (max-width: 768px) {
        padding-bottom: ${props => props.$last ? '240px' : '80px'};
    }
`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'}; 
    margin: 0 -40px;

    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        margin: -40px 0;
    }
`;

export const Content = styled.article`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 40px;

    @media screen and (max-width: 1024px) {
        margin: 20px 0;
    }
`;

export const Figure = styled.figure`
    flex: 1;
    margin: 0 40px;

    @media screen and (max-width: 1024px) {
        margin: 20px 0;
    }
`;

export const Img = styled.img`
    /* padding-right: 80px; */
    width: 100%;
    ${props => props.$radius &&
        css`border-radius: 16px;`
    };
`;

export const Icon = styled.img`
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
`;

export const StyledButton = styled(Button)`
    align-self: flex-start;
    margin-top: 8px;
`;