import styled from 'styled-components';
import { Link } from "react-scroll";
import Text from '../../components/Text/Text';

export const StyledText = styled(Text)`
    max-width: 630px;
`;

export const Flex = styled.section`
    display: flex;
    scroll-behavior: smooth;
    padding-top: 40px;
    margin: 0 -10px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding-top: 0;
        margin: 0;
    }
`;

export const Aside = styled.aside`
    margin: 0 10px;
    flex: 0 1 25%;

    @media screen and (min-width: 769px) {
        position: sticky;
        top: 140px;
        align-self: flex-start;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const ScrollLink = styled(Link)`
    font-family: var(--font-heading);
    color: var(--color-heading-on-bg);
    line-height: 1.25;
    font-size: var(--fs-h4);
    font-weight: var(--fw-bold);
    display: block;
    cursor: pointer;
    margin-bottom: 16px;
    
    .link-circle {
        margin-right: 16px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        display: inline-block;
        background-color: var(--color-placeholder);
        transition: width .2s ease;
    }

    &.feature--active {
        color: var(--color-primary);

        .link-circle {
            width: 48px;
            background-color: var(--color-primary);
        }
    }

    @media screen and (max-width: 768px) {
        flex-shrink: 0;
        margin-right: 32px;

        .link-circle {
            display: none;
        }
    }
`;


export const Section = styled.section`
    margin: 0 10px;
    flex: 0 1 75%;

    @media screen and (max-width: 768px) {
        margin: 0;
    }
`;

export const FeatureSection = styled.article`
    padding-bottom: 64px;
    
    @media screen and (max-width: 768px) {
        padding-bottom: 0;
        padding-top: 32px;
    }
`;

export const Items = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px;
    margin-top: 16px;
    
    article {
        margin: 0;
    }

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    
    @media screen and (max-width: 768px) {
        display: flex;
        margin-top: 0;
        width: calc(100% + 48px);
        margin-left: -24px;
        padding: 16px 24px;
        overflow-x: auto;
    }

    @media screen and (max-width: 425px) {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 16px;
    }
`;

export const Offset = styled.span`
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
`;