import styled from 'styled-components';

export const Tag = styled.span`
    vertical-align: bottom;
    display: inline-block;
    background-color: var(--color-tag);
    color: var(--color-text-on-bg);
    border-radius: 999px;
    padding: 6px 12px;
    font-size: var(--fs-tag);
    font-weight: var(--fw-semi-bold);
    line-height: 1;
    margin-left: 6px;
`;