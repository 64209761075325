import React from 'react'
import * as S from './IntegrationCloudInvert.elements';
import firebase from '../../assets/img/blocks/integrationCloud/firebase.jpg';
import spotify from '../../assets/img/blocks/integrationCloud/spotify.jpg';
import stager from '../../assets/img/blocks/integrationCloud/stager.jpg';
import apple from '../../assets/img/blocks/integrationCloud/apple.jpg';
import instagram from '../../assets/img/blocks/integrationCloud/instagram.jpg';
import Heading from "../Heading/Heading";
import Text from "../Text/Text";

const IntegrationCloudInvert = ({data}) => {

    const integrations = [
        { id: 1, name: 'Firebase', image: firebase, size: 30, top: 36, left: 50, duration: 9 },
        { id: 2, name: 'Spotify', image: spotify, size: 19, top: 14, left: 40, duration: 8 },
        { id: 3, name: 'Stager', image: stager, size: 18, top: 40, left: 19, duration: 8.5 },
        { id: 4, name: 'Apple', image: apple, size: 10, top: 65, left: 29, duration: 8.8 },
        { id: 5, name: 'Instagram', image: instagram, size: 20, top: 73, left: 41, duration: 8.2 },
    ]

    return (
        <S.Flex>
            <S.TextBox>
                <Heading tag="h2">{data[0]?.connectTitle}</Heading>
                <Text>{data[0]?.connectText}</Text>
            </S.TextBox>
            <S.CloudBox>
                <S.IntegrationCloud>
                    {integrations.map(item => (
                        <S.Logo key={item.id} size={item.size} top={item.top} left={item.left} duration={item.duration}>
                            <img src={item.image} alt={'Logo of ' + item.name} loading='lazy'/>
                        </S.Logo>
                    ))}
                </S.IntegrationCloud>
            </S.CloudBox>
        </S.Flex>
    )
}

export default IntegrationCloudInvert
