import bg from '../locales/bg/translation.json';
import cs from '../locales/cs/translation.json';
import da from '../locales/da/translation.json';
import de from '../locales/de/translation.json';
import el from '../locales/el/translation.json';
import en from '../locales/en/translation.json';
import es from '../locales/es/translation.json';
import fi from '../locales/fi/translation.json';
import fr from '../locales/fr/translation.json';
import hu from '../locales/hu/translation.json';
import it from '../locales/it/translation.json';
import ja from '../locales/ja/translation.json';
import nb from '../locales/nb/translation.json';
import nl from '../locales/nl/translation.json';
import pl from '../locales/pl/translation.json';
import pt from '../locales/pt-pt/translation.json';
import ro from '../locales/ro/translation.json';
import sk from '../locales/sk/translation.json';
import sl from '../locales/sl/translation.json';
import sv from '../locales/sv/translation.json';
import zh from '../locales/zh/translation.json';

export const languagesData = [
    { language: "Bulgarian", code: "bg", locale: bg },
    { language: "Chinese", code: "zh", locale: zh },
    { language: "Czech", code: "cs", locale: cs },
    { language: "Danish", code: "da", locale: da },
    { language: "Dutch", code: "nl", locale: nl },
    { language: "English", code: "en", locale: en },
    { language: "Finnish", code: "fi", locale: fi },
    { language: "French", code: "fr", locale: fr },
    { language: "German", code: "de", locale: de },
    { language: "Greek", code: "el", locale: el },
    { language: "Hungarian", code: "hu", locale: hu },
    { language: "Italian", code: "it", locale: it },
    { language: "Japanese", code: "ja", locale: ja },
    { language: "Norwegian", code: "nb", locale: nb },
    { language: "Polish", code: "pl", locale: pl },
    { language: "Portuguese", code: "pt-pt", locale: pt },
    { language: "Romanian", code: "ro", locale: ro },
    { language: "Slovak", code: "sk", locale: sk },
    { language: "Slovenian", code: "sl", locale: sl },
    { language: "Spanish", code: "es", locale: es },
    { language: "Swedish", code: "sv", locale: sv },
]