import React, {useEffect, useState} from 'react'
import * as S from './Team.elements';
import { HiChevronDown } from 'react-icons/hi';
import {Await} from "react-router-dom";
import ContainerMedium from "../ContainerMedium/ContainerMedium";
import ContainerSmall from "../ContainerSmall/ContainerSmall";
import Wrapper from "../Wrapper/Wrapper";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";

const Team = ({data}) => {
    const {t} = useTranslation();
    const [count, setCount] = useState(null);
    const [loadedCount, setLoadedCount] = useState(3);
    const [hasMoreTeamMembers, setHasMoreTeamMembers] = useState(true);

    // Data is a promise, so chain to set selected
    useEffect(() => {
        data.then((team) => {
            setCount(team[0]?.teamMembers?.length);

            if (loadedCount >= team[0]?.teamMembers?.length) {
                setHasMoreTeamMembers(false)
            }
        });
        //eslint-disable-next-line
    }, [data])

    const handleLoadMore = () => {
        setLoadedCount(loadedCount + 3)

        if (loadedCount + 3 >= count) {
            setHasMoreTeamMembers(false)
        }
    }

    return (
        <React.Suspense>
            <Await resolve={data}>
                {(data) => (data[0]?.teamMembers?.length &&
                    <Wrapper $exclude="top">
                        <ContainerSmall as="header">
                            <Heading tag="h2">{data[0]?.title}</Heading>
                        </ContainerSmall>
                        <ContainerMedium>
                            <S.Grid>
                                {data[0]?.teamMembers?.slice(0, loadedCount).map(item => (
                                    <div key={item.id}>
                                        <S.VerticalImageOuter>
                                            <S.VerticalImageInner src={item?.imageUrl} alt="" />
                                        </S.VerticalImageOuter>
                                        <S.StyledTitle>{item.title}</S.StyledTitle>
                                        <Text $noMargin>{item.position}</Text>
                                    </div>
                                ))}
                            </S.Grid>
                        </ContainerMedium>
                        {hasMoreTeamMembers &&
                            <ContainerSmall>
                                <Button secondary="true" as="button" onClick={handleLoadMore} $chevron>{t('general.loadMore_label')} <HiChevronDown/></Button>
                            </ContainerSmall>
                        }
                    </Wrapper>
                )}
            </Await>
        </React.Suspense>
    )
}

export default Team
