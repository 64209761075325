import React, {useEffect, useRef, useState} from 'react'
import * as S from './BlogsOverview.elements';
import { HiChevronDown } from 'react-icons/hi';
import {Await, useAsyncValue, useSearchParams} from "react-router-dom";
import ContainerMedium from "../ContainerMedium/ContainerMedium";
import ContainerSmall from "../ContainerSmall/ContainerSmall";
import Wrapper from "../Wrapper/Wrapper";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";
import Button from "../Button/Button";
import BlogCard from "../BlogCard/BlogCard";
import {useTranslation} from "react-i18next";
import SegmentedControl from "../SegmentedControl/SegmentedControl";

const BlogsOverview = ({data, title, description, exclude, last = false}) => {
    const {t} = useTranslation();
    const [loadedCount, setLoadedCount] = useState(localStorage.getItem("blogsCount") ? JSON.parse(localStorage.getItem("blogsCount")) : 6);
    let [searchParams, setSearchParams] = useSearchParams();

    return (
        <Wrapper $exclude={exclude} last={last}>
            <ContainerSmall as="header">
                <Heading tag="h2">{title}</Heading>
                <Text>{description}</Text>
            </ContainerSmall>
            <ContainerSmall $noPadding>
                <S.SegmentedControlWrapper>
                    <SegmentedControl
                        name="blogs"
                        segments={[
                            {
                                label: t('blog.switch.all'),
                                value: "",
                                ref: useRef()
                            },
                            {
                                label: t('blog.switch.blogs'),
                                value: "blogs",
                                ref: useRef()
                            },
                            {
                                label: t('blog.switch.features'),
                                value: "features",
                                ref: useRef()
                            }
                        ]}
                        callback={(value) => {
                            if(value === "") {
                                searchParams.delete("filter");
                            } else {
                                searchParams.set("filter", value);
                            }
                            setSearchParams(searchParams, {preventScrollReset: true})
                        }}
                        defaultIndex={searchParams.get("filter") === "features" ? 2 : searchParams.get("filter") === "blogs" ? 1 : 0}
                        controlRef={useRef()}
                    />
                </S.SegmentedControlWrapper>
            </ContainerSmall>
            <React.Suspense fallback={
                <ContainerMedium>
                    <S.Grid>
                        {Array.from({length: loadedCount}, (_, index) => (
                            <BlogCard key={index} loading="true"/>
                        ))}
                    </S.Grid>
                </ContainerMedium>
            }>
                <Await resolve={data}>
                    <Blogs
                        loadedCount={loadedCount}
                        setLoadedCount={setLoadedCount}
                    />
                </Await>
            </React.Suspense>
        </Wrapper>
    );
}

function Blogs({loadedCount, setLoadedCount}) {
    const data = useAsyncValue();
    const {t} = useTranslation();
    let [searchParams] = useSearchParams();

    const currentFilterType = searchParams.get("filter") === "features" ? "feature" : searchParams.get("filter") === "blogs" ? "blog" : null;
    const filteredData = currentFilterType ? data?.filter(item => item.type === currentFilterType) : data;
    const [hasMoreBlogs, setHasMoreBlogs] = useState(loadedCount < filteredData.length);

    useEffect(() => {
        setHasMoreBlogs(loadedCount < filteredData.length)
        //eslint-disable-next-line
    }, [currentFilterType]);

    const handleLoadMore = () => {
        setLoadedCount(loadedCount + 6)
        localStorage.setItem("blogsCount", JSON.stringify(loadedCount + 6));

        if (loadedCount + 6 >= filteredData.length) {
            setHasMoreBlogs(false)
        }
    }

    return (
        <>
            <ContainerMedium>
                <S.Grid>
                    {(filteredData?.slice(0, loadedCount).map(item => (
                        <BlogCard key={item.id} data={item} />
                    )))}
                </S.Grid>
            </ContainerMedium>
            {(hasMoreBlogs) &&
                <ContainerSmall>
                    <Button secondary="true" as="button" onClick={handleLoadMore} $chevron>{t('general.loadMore_label')} <HiChevronDown/></Button>
                </ContainerSmall>
            }
        </>
    );
}

export default BlogsOverview
