import styled from "styled-components";
import {createPortal} from "react-dom";
import {Transition} from "react-transition-group";
import React, {useEffect, useRef, useState} from "react";
import {usePopupLinkContext} from "../../contexts/PopupLinkContext";
import {Link, useLoaderData, useMatch, useNavigate, useParams} from "react-router-dom";
import Text from '../../components/Text/Text';
import Heading from "../../components/Heading/Heading";
import {HiX} from 'react-icons/hi';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {Seo} from "../../components/Seo/Seo";

const StyledFunctionalityPopup = styled.div`
    pointer-events: none;
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    z-index: 102;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: 1; }
    &.entered { opacity: 1; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

const PopupInner = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    max-width: 540px;
    max-height: 100%;
    overflow-x: auto;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 17px;
`;

const CloseButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 999;
    width: 24px;
    height: 24px;
    
    svg {
        color: var(--color-black);
        font-size: 2.4rem;
    }
`;

const Outer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 19;

    @supports not (aspect-ratio: 16 / 19) {
        overflow: hidden;
        padding-top: 118.75%;
    }
`;

const Inner = styled.div`
    display: block;
    width: 100%;
    height: 100%;

    @supports not (aspect-ratio: 16 / 19) {
        position: absolute;
        inset: 0;
    }
`;

const StyledSlider = styled(Slider)`
    width: 100%;
    height: 100%;
    overflow: hidden;
    
    .slick-dots {
        bottom: 20px;
        left: 50%;
        width: unset;
        transform: translateX(-50%);

        li {
            display: inline-block;
            transition: all .2s;
            width: 8px;
            height: 8px;

            button {
                transition: all .2s;
                background-color: #D7D7D7;
                border-radius: 7px;
                width: 8px;
                height: 8px;

                &:before {
                    content: none;
                }
            }

            &.slick-active {
                transition: all .2s;
                width: 30px;

                button {
                    background-color: var(--color-text);
                    width: 30px;
                }
            }
        }
    }
    
    .slick-slide {
        border: none;
        overflow: hidden;
    }
    
    div {
        outline: none;
    }
`;

const Slide = styled.div`
    width: 100%;
    height: 100%;
    
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const Content = styled.div`
    padding: 22px 30px;
`;

const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-black);
    height: 100%;
    width: 100%;
    z-index: 101;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: .5; }
    &.entered { opacity: .5; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

const LinkWrapper = styled.div`
    margin: 8px 0;
`;

const StyledLink = styled(Link)`
    font-size: var(--font-size-default);
    font-weight: var(--fw-semi-bold);
    line-height: 1.5;
    color: #000;
    text-decoration: none;
    background: linear-gradient(to bottom, var(--color-surface) 0%, var(--color-surface) 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 4px 50%;
    transition: background-size .2s;

    :hover {
        background-size: 4px 100%;
    }
`;

export async function functionalityPopupLoader({request, params}) {
    const lang = params?.lang;

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/features/${params?.slug}${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page is not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}

export const FunctionalityPopup = () => {
    const {langForInUrl} = useCurrentLangCode();
    const {data} = useLoaderData();
    const {viaInternalLink} = usePopupLinkContext();
    const navigate = useNavigate();

    const matchPricing = useMatch("/:lang?/pricing/:pricingPageType?/:slug?");
    const isPricingPage = Boolean(matchPricing);
    const params = useParams();
    const pricingUrl = params?.pricingPageType ? `pricing/${params.pricingPageType}` : 'pricing';
    const isExternalButtonLink = data?.buttonLink?.startsWith("http");

    const functionalityPopupRef = useRef(null);
    const underlayNodeRef = useRef(null);
    const touchActionStyle = useRef("");
    const overflowStyle = useRef("");
    const [isOpen, setIsOpen] = useState(!viaInternalLink);

    const openPopupLink = () => {
        setIsOpen(true);
        touchActionStyle.current = document.body.style.touchAction;
        document.body.style.touchAction = "none";
        overflowStyle.current = document.body.style.overflow;
        document.body.style.overflow = "hidden";
    };

    const closePopupLink = () => {
        setIsOpen(false);
        document.body.style.touchAction = touchActionStyle.current;
        document.body.style.overflow = overflowStyle.current;
    };

    useEffect(() => {
        openPopupLink();

        return () => closePopupLink();
    }, []);

    let closeTimeout;
    const handleClosePopupLink = () => {
        clearTimeout(closeTimeout);

        closePopupLink();

        closeTimeout = setTimeout(() => {
            isPricingPage
                ? navigate(`${langForInUrl}/${pricingUrl}`, { preventScrollReset: true })
                : navigate(`${langForInUrl}/functionalities`, { preventScrollReset: true });
        }, 200);
    }

    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
    };

    return createPortal(
        <>
            <Seo
                title={data?.title}
                description={data?.text}
                slug={isPricingPage ? `/${pricingUrl}/${data?.slug}` : `/functionalities/${data?.slug}`}
            />

            <Transition nodeRef={functionalityPopupRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <StyledFunctionalityPopup ref={functionalityPopupRef} className={state}>
                        <PopupInner>
                            <CloseButton onClick={handleClosePopupLink}>
                                <HiX />
                            </CloseButton>
                            {data?.slides?.length > 0 &&
                                <Outer>
                                    <Inner>
                                        <StyledSlider {...settings}>
                                            {data.slides.map(item => {
                                                if(!item.imageUrl) return null;

                                                return (
                                                    <Slide key={item.id}>
                                                        <img src={item.imageUrl} alt={item.alt ?? ""} />
                                                    </Slide>
                                                );
                                            })}
                                        </StyledSlider>
                                    </Inner>
                                </Outer>
                            }
                            <Content>
                                <Heading tag="h3">{data?.title}</Heading>
                                <Text>{data?.text}</Text>
                                {(data?.buttonLabel && data?.buttonLink) &&
                                    <LinkWrapper>
                                        <StyledLink
                                            to={isExternalButtonLink ? data.buttonLink : `${langForInUrl}/${data.buttonLink}`}
                                            target={isExternalButtonLink ? "_blank" : "_self"}
                                            rel={isExternalButtonLink ? "noreferrer" : undefined}
                                        >
                                            {data.buttonLabel}
                                        </StyledLink>
                                    </LinkWrapper>
                                }
                            </Content>
                        </PopupInner>
                    </StyledFunctionalityPopup>
                )}
            </Transition>

            <Transition nodeRef={underlayNodeRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <Underlay ref={underlayNodeRef} className={state} onClick={handleClosePopupLink} />
                )}
            </Transition>
        </>, document.body
    );
}