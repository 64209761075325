import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'
import * as S from './Navbar.elements';
import { HiOutlinePhone, HiOutlineMail, HiOutlineLocationMarker } from 'react-icons/hi';
import hexagon from '../../assets/img/decorations/deco-hexagon.svg';
import logo from '../../assets/img/logo.svg';
import {FaInstagram} from "react-icons/fa";
import {RiAppleLine, RiFacebookCircleLine} from "react-icons/ri";
import {PiAndroidLogoBold, PiLinkedinLogoBold} from "react-icons/pi";
import {LuTwitter} from "react-icons/lu";
import {NavbarLanguage} from "./NavbarLanguage";
import {useTranslation} from "react-i18next";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {useSize} from "react-use";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {useChangeNavbarOnScroll} from "../../hooks/useChangeNavbarOnScroll";
import {useBreakpointContext} from "../../contexts/BreakpointContext";
import {NavbarAccordion} from "./NavbarAccordion";
import {NavbarDropdown} from "./NavbarDropdown";

const Navbar = () => {
    const {t} = useTranslation();
    const {langForInUrl} = useCurrentLangCode();

    // Close menu and accordion on click
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => setIsOpen(!isOpen);
    const closeMenu = () => { setIsOpen(false); }

    // Handle navbar scroll effects
    const {scrolled} = useChangeNavbarOnScroll();

    // Set overflow hidden if isOpen
    useEffect(() => {
        isOpen && (document.body.style.overflowY = 'hidden');
        !isOpen && (document.body.style.overflowY = 'unset');
    }, [isOpen ]);

    // Initial breakpoint = 1024px, but if the logo shrinks it will change the breakpoint to current browsers width for other languages
    const {breakpoint, setBreakpoint} = useBreakpointContext();
    const {width: windowWidth} = useWindowDimensions();

    const [logoComponent, {width}] = useSize(() => (
        <Link to={`${langForInUrl}/`} aria-label="Home" onClick={closeMenu}>
            <S.Logo src={logo} alt="The logo of TMSQR"/>
        </Link>
    ), { width: 181 });

    useEffect(() => {
        if(width <= 130 && windowWidth > 1024) {
            setBreakpoint(windowWidth);
        }
        //eslint-disable-next-line
    }, [width, windowWidth])

    let isMobile = windowWidth <= breakpoint;

    return (
        <S.StyledNavbar scrolled={scrolled} isOpen={isOpen}>
            <S.NavContainer>
                {logoComponent}

                <S.Menu isOpen={isOpen}>
                    <S.MenuBox>
                        <S.Item>
                            <S.StyledNavLink to={`${langForInUrl}/`} onClick={closeMenu} end>{t('navbar.home_label')}</S.StyledNavLink>
                        </S.Item>
                        <S.Item>
                            <S.StyledNavLink to={`${langForInUrl}/functionalities`} onClick={closeMenu} >{t('navbar.functionalities_label')}</S.StyledNavLink>
                        </S.Item>
                        <S.Item>
                            <S.StyledNavLink to={`${langForInUrl}/blog`} onClick={closeMenu} >{t('navbar.blogsAndLabs_label')}</S.StyledNavLink>
                        </S.Item>
                        <S.Item>
                            <S.StyledNavLink to={`${langForInUrl}/cases`} onClick={closeMenu} >{t('navbar.cases_label')}</S.StyledNavLink>
                        </S.Item>
                        <S.Item>
                            {isMobile
                                ? <NavbarAccordion label={t('navbar.pricing_label')} closeMenu={closeMenu} />
                                : <NavbarDropdown label={t('navbar.pricing_label')} closeMenu={closeMenu} />
                            }
                        </S.Item>
                        <S.Item>
                            <S.StyledNavLink to={`${langForInUrl}/about`} onClick={closeMenu} >{t('navbar.about_label')}</S.StyledNavLink>
                        </S.Item>
                    </S.MenuBox>
                    <S.MenuBox>
                        <S.MobileOnly>
                            <S.Item>
                                <S.StyledNavLink to="https://tmsqr.app/Security/login" target="_blank" rel="noreferrer" >{t('navbar.login_label')}</S.StyledNavLink>
                            </S.Item>
                            <S.StyledButton primary="true" to={`${langForInUrl}/contact-us`} onClick={closeMenu} >{t('navbar.contact_label')}</S.StyledButton>

                            {isOpen &&
                                <>
                                    <S.ContactList>
                                        <li><a href="tel:+31712034054"><HiOutlinePhone /> +31 71 2034054</a></li>
                                        <li><a href="mailto:info@tmsqr.com"><HiOutlineMail /> info@tmsqr.com</a></li>
                                        <li><a href="https://www.google.com/maps/place/Dorus+Rijkersweg+15,+2315+WC+Leiden/@52.1594546,4.5116685,17z/data=!3m1!4b1!4m5!3m4!1s0x47c5c69d22ac9f61:0x8e014dc1d00a1e65!8m2!3d52.1594546!4d4.5138572" target="_blank" rel="noreferrer"><HiOutlineLocationMarker /> Dorus Rijkersweg 15<br/>2315WC Leiden<br/>The Netherlands</a></li>
                                    </S.ContactList>
                                    <S.IconList>
                                        <a href="https://www.instagram.com/tmsqr.app/" target="_blank" rel="noreferrer"><FaInstagram /></a>
                                        <a href="https://www.facebook.com/TMSQR/" target="_blank" rel="noreferrer"><RiFacebookCircleLine /></a>
                                        <a href="https://www.linkedin.com/company/tmsqr/" target="_blank" rel="noreferrer"><PiLinkedinLogoBold /></a>
                                        <a href="https://twitter.com/tmsqr_app" target="_blank" rel="noreferrer"><LuTwitter /></a>
                                        <a href="https://apps.apple.com/nl/app/timesquare/id731062580" target="_blank" rel="noreferrer"><RiAppleLine /></a>
                                        <a href="https://play.google.com/store/apps/details?id=com.zooma.timesquare" target="_blank" rel="noreferrer"><PiAndroidLogoBold /></a>
                                    </S.IconList>

                                    <S.DecorationHexagon src={hexagon} alt="" draggable="false" />
                                </>
                            }
                        </S.MobileOnly>
                    </S.MenuBox>
                </S.Menu>

                <S.ExtraBox>
                    <S.Item>
                        <S.StyledNavLink to="https://tmsqr.app/admin" target="_blank" rel="noreferrer">{t('navbar.login_label')}</S.StyledNavLink>
                    </S.Item>
                    <NavbarLanguage />
                    <S.StyledButton primary="true" to={`${langForInUrl}/contact-us`} onClick={closeMenu} >{t('navbar.contact_label')}</S.StyledButton>
                </S.ExtraBox>

                <S.HamburgerWrapper>
                    <NavbarLanguage />

                    <S.HamburgerIcon onClick={handleClick} isOpen={isOpen}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </S.HamburgerIcon>
                </S.HamburgerWrapper>

            </S.NavContainer>
        </S.StyledNavbar>
    )
}

export default Navbar
