import TextHero from "../../blocks/TextHero/TextHero";
import React from "react";
import {Paragraphs} from "../../components/Paragraphs/Paragraphs";
import {useTranslation} from "react-i18next";
import {Seo} from "../../components/Seo/Seo";

const ErrorPage = () => {
    const {t} = useTranslation();

    let title = t('error.notFound_title');
    let text = `<p>${t('error.notFound_text')}</p>`;

    const data = {
        "paragraphs": [
            {
                "id": "errorpage-text-paragraph",
                "type": "text",
                "text": text
            }
        ]
    }

    return (
        <>
            <Seo title={title} />

            <TextHero title={title} />

            <Paragraphs data={data?.paragraphs} />
        </>
    );
}

export default ErrorPage
