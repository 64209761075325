import React from 'react'
import * as S from './BlogCard.elements';
import { HiArrowSmRight } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {useTranslation} from "react-i18next";
import {Tag} from "../Tag/Tag";

const BlogCard = ({data, loading}) => {
    const {langForInUrl} = useCurrentLangCode();
    const {t} = useTranslation();

    if(loading) {
        return (
            <S.StyledBlogCard>
                <S.SkeletonFigure>
                    <Skeleton height="200px" width="100%" />
                </S.SkeletonFigure>

                <S.InnerBlogCard>
                    <Text><Skeleton width="30%" /></Text>
                    <Heading tag="h4"><Skeleton width="90%" /></Heading>
                    <Text><Skeleton width="75%" count={3} /></Text>
                    <S.ReadMore>
                        <S.ReadMoreText><Skeleton width={120} /></S.ReadMoreText>
                    </S.ReadMore>
                </S.InnerBlogCard>
            </S.StyledBlogCard>
        )
    }

    return (
        <S.StyledBlogCard as={!data.slug ? 'article' : undefined} to={`${langForInUrl}/blog/${data.slug}`} $isLink={Boolean(data.slug)}>
            {data.imageUrl &&
                <S.OuterImage>
                    <S.InnerImage src={data.imageUrl} alt="" />
                </S.OuterImage>
            }
            <S.InnerBlogCard>
                <Text>{data.date} <Tag>{t(`blog.type.${data?.type ? data.type : "blog"}`)}</Tag></Text>
                <Heading tag="h4">{data.title}</Heading>
                <Text $truncate $clamp="3">{data.text}</Text>
                <S.ReadMore>
                    <S.ReadMoreText>{t('general.readMore_label')} <HiArrowSmRight/></S.ReadMoreText>
                </S.ReadMore>
            </S.InnerBlogCard>
        </S.StyledBlogCard>
    )
}

export default BlogCard
