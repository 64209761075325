import React from 'react';
import * as S from '../BlogsAndLabsDetail/BlogsAndLabsDetail.elements';
import {useLoaderData} from "react-router-dom";
import Container from "../../components/Container/Container";
import {Paragraphs} from "../../components/Paragraphs/Paragraphs";
import {Seo} from "../../components/Seo/Seo";
import Hero from "../../blocks/Hero/Hero";

export async function networkLoader({request, params}) {
    const lang = params?.lang;

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/network${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page is not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}


export const Network = () => {
    const {data} = useLoaderData();

    return (
        <>
            <Seo
                title={data[0]?.title}
                description={data[0]?.text}
                slug="/tmsqr-network"
                image={data[0]?.imageUrl}
            />

            <Hero
                type="overlap" $small
                heading={data[0]?.title}
                text={data[0]?.text}
                supportWordBreak={true}
            />

            <S.HeroOverlap>
                <Container>
                    <S.Outer>
                        <S.Inner src={data[0]?.imageUrl} alt="" />
                    </S.Outer>
                </Container>
            </S.HeroOverlap>

            <Paragraphs data={data[0]?.paragraphs} />
        </>
    );
}