import {useEffect, useState} from "react";
import {Await, NavLink, useMatch, useRouteLoaderData} from "react-router-dom";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import styled, {css} from "styled-components";
import {StyledNavLink} from "./Navbar.elements";
import {LuChevronDown} from "react-icons/lu";
import React from "react";
import {TMSQRSpinner} from "../Loading/Loading";

const DropdownWrapper = styled.div`
    position: relative;
`;

const NavButton = styled(StyledNavLink)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledChevron = styled(LuChevronDown)`
    width: 24px;
    height: 24px;
    transition: transform .2s ease;

    ${({$open}) => $open && css`
        transform: rotate(-180deg);
    `}
`;

const Accordion = styled.div``;

const AccordionList = styled.div`
    margin-top: 16px;
`;

const AccordionItem = styled(NavLink)`
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 6px 0;

    &:not(:last-of-type) {
        margin-bottom: 4px;
    }
`;

const Icon = styled.img`
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    flex-shrink: 0;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.p`
    font-family: var(--font-button);
    font-weight: var(--fw-bold);
    line-height: 1.25;
    font-size: var(--fs-nav-dropdown);
    color: var(--color-heading);
    text-decoration: none;
    
    ${AccordionItem}.active & {
        text-decoration: underline;
    }
`;

const Subtitle = styled.p`
    line-height: 1.25;
    font-size: var(--fs-nav-dropdown);
    color: var(--color-text);
    text-decoration: none;
`;

export const NavbarAccordion = ({label, closeMenu}) => {
    const {langForInUrl} = useCurrentLangCode();
    const {navigationData} = useRouteLoaderData("root");
    const [open, setOpen] = useState(false);
    const match = useMatch("/:lang?/pricing/:pricingPageType?/:slug?");

    useEffect(() => {
        setOpen(Boolean(match))
    }, [match]);

    const toggleAccordion = () => {
        setOpen(!open);
    }

    return (
        <DropdownWrapper>
            <NavButton as="button" className={match ? "active" : ""} onClick={toggleAccordion}>{label} <StyledChevron $open={open} /></NavButton>

            {open &&
                <Accordion>
                    <React.Suspense fallback={<TMSQRSpinner/>}>
                        <Await resolve={navigationData}>
                            {(navigationData) => (
                                <AccordionList>
                                    {navigationData?.map(item => (
                                        <AccordionItem key={item.id} to={`${langForInUrl}/pricing/${item.slug}`} onClick={closeMenu}>
                                            {item.icon && <Icon src={item.icon} alt=""/>}
                                            <Content>
                                                <Title>{item.title}</Title>
                                                {item.subtitle &&
                                                    <Subtitle>{item.subtitle}</Subtitle>}
                                            </Content>
                                        </AccordionItem>
                                    ))}
                                </AccordionList>
                            )}
                        </Await>
                    </React.Suspense>
                </Accordion>
            }
        </DropdownWrapper>
    );
}