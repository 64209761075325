import React from 'react'
import * as S from './CoreValue.elements';
import { Heading } from '../../components';
import {parseHTMLText} from "../../helpers/parseHTMLText";

const CoreValue = ({image, title, text, langForInUrl}) => {
    return (
        <S.CoreValue>
            <S.Figure>
                <S.Img src={image} alt="" />
            </S.Figure>
            <Heading white="true" tag="h4">{title}</Heading>
            <S.StyledText dangerouslySetInnerHTML={{__html: parseHTMLText(text, langForInUrl)}}/>
        </S.CoreValue>
    )
}

export default CoreValue
