import React from 'react'
import * as S from './TextHero.elements';
import Heading from "../../components/Heading/Heading";
import {RichText} from "../../components/Text/Text";
import ContainerSmall from "../../components/ContainerSmall/ContainerSmall";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {parseHTMLText} from "../../helpers/parseHTMLText";

const TextHero = ({title, text}) => {
    const {langForInUrl} = useCurrentLangCode();

    return (
        <S.StyledTextHero>
            <ContainerSmall>
                <Heading tag="h1">{title}</Heading>
                <RichText dangerouslySetInnerHTML={{__html: parseHTMLText(text, langForInUrl)}} />
            </ContainerSmall>

            <svg viewBox="0 0 500 40" preserveAspectRatio="none">
                <path d="M0 40 0 0Q250 40 500 0L500 40Z" fill="#fcfcfc" />
            </svg>
        </S.StyledTextHero>
    )
}

export default TextHero