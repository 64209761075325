import styled from 'styled-components';
import {RichText} from '../../components/Text/Text';

export const CoreValue = styled.article`
    background-color: var(--color-secondary);
    padding: 36px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    text-align: center;

    @media screen and (max-width: 992px) {
        padding: 24px;
    }
`;

export const Figure = styled.figure`
    margin-bottom: 16px;
`;

export const Img = styled.img`
    width: 100px;
    height: 100px;
`;

export const StyledText = styled(RichText)`
    p {
        color: var(--color-white);
    }
    
    a {
        font-weight: var(--fw-semi-bold);
        color: var(--color-white);
        font-size: 1.6rem;
        line-height: 1.35;
        background: linear-gradient(to bottom, rgba(251, 217, 4, .3) 0%, rgba(251, 217, 4, .3) 100%);
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 4px 50%;
        text-decoration: none;
        transition: background-size .2s;

        :hover {
            background-size: 4px 100%;
        }

        @media screen and (max-width: 576px) {
            font-size: 1.4rem;
        }
    }
`;