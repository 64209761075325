import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import reset from 'styled-reset-advanced';

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${normalize}

    * { 
        box-sizing: border-box;
        user-select: initial;
    }

    :root {
        // Colors
        --color-primary: #FBD904;
        --color-primary-60: #E7C704;
        --color-secondary: #434343;
        --color-secondary-40: #737373;
        
        --color-white: #fff;
        --color-black: #171717;
        --color-background: #FCFCFC;
        --color-background-60: #F6F6F6;
        --color-surface: #FCF5CA;
        --color-surface-30: #FEF9D9;
        --color-underlay: var(--color-primary);
        --color-dropdown: #FAFAFA;
        --color-tag: #EDEDED;
        
        --color-heading: var(--color-black);
        --color-text: var(--color-secondary);
        --color-text-hover: var(--color-secondary-40);
        --color-button: var(--color-black);
        
        --color-navbar-background: var(--color-white);
        --color-navbar-hamburger: var(--color-black);

        --color-nav-footer: #F9FBF9;
        --color-error: #FF7A7A;
        --color-border: #EEEEEE;
        --color-border-hover: #C4C4C4;
        --color-info-banner-primary: var(--color-primary);
        --color-info-banner-secondary: #2E2E2E;
        --color-stroke: #F4F4F4;
        --color-button-on-primary: #E9CA09;
        --color-button-on-primary-hover: #DCBF07;

        --color-heading-on-bg: #171717;
        --color-text-on-bg: var(--color-secondary);
        --color-text-on-bg-hover: #959595;
        --color-heading-on-surface: #04575D;
        --color-text-on-surface: #04575D;
        --color-placeholder: #DEDEDE;
        --color-switch-outer: #F9F3C8;
        --color-table-odd-rows: #f5f5f5;
 
        // Fonts
        --font-heading: 'Outfit', sans-serif;
        --font-text: 'Manrope', sans-serif;
        --font-button: var(--font-heading);

        // Font weights
        --fw-light: 300;
        --fw-regular: 400;
        --fw-medium: 500;
        --fw-semi-bold: 600;
        --fw-bold: 700;
        --fw-extra-bold: 800;

        // Font sizes
        --font-size-default: 1.6rem;
        --font-size-nav-link: 1.6rem;
        --font-size-nav-dropdown: 1.6rem;
        --fs-nav-link: 1.6rem;
        --fs-nav-lang: 1.6rem;
        --fs-h1: 4rem;
        --fs-h2: 3rem;
        --fs-h3: 2.4rem;
        --fs-h3-smaller: 1.8rem; //1.8rem
        --fs-h4: 1.8rem;
        --fs-dropdown-body: 1.4rem;
        --fs-button: 1.6rem;
        --font-size-nav-sub: 1.4rem;
        --font-size-nav-contact: 1.6rem;
        --font-size-tooltip: 1.4rem;
        --fs-nav-dropdown: 1.6rem;
        --fs-tag: 1.2rem;

        @media screen and (max-width: 1024px) {
            --font-size-nav-link: 2rem;
            --fs-h1: 3.5rem;
            --fs-h2: 2.8rem;
            --fs-nav-link: 2rem;
            --fs-h4: 1.6rem;
        }

        @media screen and (max-width: 768px) {
            --font-size-nav-dropdown: 1.4rem;
            --fs-h1: 3.3rem;
            --fs-h2: 2.5rem;
        }

        // Box shadows
        --box-shadow-navbar: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .05);

        /* Width */
        --width-container: 1440px;
    }

    html, body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
        font-size: 62.5%;
        font-family: var(--font-text), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        background-color: var(--color-background);
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        tab-size: 4;
        font-feature-settings: normal;
    }

    ::selection {
        color: var(--color-white);
        background: var(--color-primary);
    }

    /* NProgress navigation loader bar */
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-primary);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }
`;

export default GlobalStyle;