import React from 'react'
import * as S from './Bullet.elements';
import { Heading, Text } from '../../components';
import checkIcon from '../../assets/img/blocks/bulletSection/check.svg';

const Bullet = ({heading, description}) => {
    return (
        <S.Item>
            <S.Img src={checkIcon} alt={""} />
            <Heading textOnSurface tag="h4">{heading}</Heading>
            <Text textOnSurface>{description}</Text>
        </S.Item>
    )
}

export default Bullet
