import * as S from './Loading.elements';

const Loading = ({children}) => {

    return (
        <>
            <S.LoadingContainer>
                <TMSQRSpinner />
                <S.LoadingText>{children}</S.LoadingText>
            </S.LoadingContainer>
        </>
    )
}

export const TMSQRSpinner = () => {
    return (
        <S.Spinner>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </S.Spinner>
    );
}

export default Loading
