import React from 'react';
import * as S from './Cases.elements';
import {TextImage, Process} from '../../components';
import teamInOffice from '../../assets/img/blocks/textImage/team-in-office.jpg';
import {defer, Outlet, useLoaderData, useParams, useRouteLoaderData} from "react-router-dom";
import CasesOverview from "../../components/CasesOverview/CasesOverview";
import AtmosphericHero from "../../blocks/AtmosphericHero/AtmosphericHero";
import {Seo} from "../../components/Seo/Seo";
import {useTranslation} from "react-i18next";

export async function casesLoader({request, params}) {
    const lang = params?.lang;

    const casesData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cases-page${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const approachData = fetch(`${process.env.REACT_APP_API_BASE_URL}/approach${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    return defer({casesData, approachData});
}

const Cases = () => {
    const {t} = useTranslation();
    const {casesData, approachData} = useLoaderData();
    const {data} = useRouteLoaderData("root");
    let {slug} = useParams(); // If there is a slug, don't render Seo component to prevent duplicate hreflang tags

    return (
        <>
            {!Boolean(slug) &&
                <Seo
                    title={t('navbar.cases_label')}
                    description={casesData[0]?.metaDescription}
                    slug="/cases"
                />
            }

            <AtmosphericHero
                title={casesData[0]?.title}
                text={casesData[0]?.text}
                location="cases"
            />

            <CasesOverview
                data={data}
                title={casesData[0]?.casesTitle}
                description={casesData[0]?.casesText}
                exclude="top"
            />

            <Process data={approachData}/>

            <S.Spacing>
                <TextImage
                    image={teamInOffice}
                    last={true}
                    radius={true}
                    title={casesData[0]?.improvementTitle}
                    text={casesData[0]?.improvementText}
                    buttonText={casesData[0]?.improvementButtonLabel}
                    buttonUrl={casesData[0]?.improvementButtonLink}
                    reverse={false}
                />
            </S.Spacing>

            <Outlet />
        </>
    );
}

export default Cases
