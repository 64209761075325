export const redirectPermanently = (location) => {
    return new Response("", {
        status: 301,
        headers: {
            Location: location,
        },
    })
};

export const oldUrls = [
    {
        oldPath: "/festivals",
        newPath: "/"
    },
    {
        oldPath: "/venue",
        newPath: "/"
    },
    {
        oldPath: "/artists",
        newPath: "/"
    },
    {
        oldPath: "/trial",
        newPath: "/contact-us"
    },
    {
        oldPath: "/our-story",
        newPath: "/about"
    },
    {
        oldPath: "/terms-conditions",
        newPath: "/terms-and-conditions"
    },
    {
        oldPath: "/blog/feature-highlight-new-tmsqr-com-website",
        newPath: "/blog"
    },
    {
        oldPath: "/blog/timesquare-feature-1-the-unique-promolink",
        newPath: "/blog/feature-the-unique-promolink"
    },
    {
        oldPath: "/blog/feature-2-push-notification",
        newPath: "/blog/feature-push-notification"
    },
    {
        oldPath: "/blog/feature-3-free-trial-app",
        newPath: "/blog"
    },
    {
        oldPath: "/blog/timesquare-in-times-of-covid-19",
        newPath: "/blog"
    },
    {
        oldPath: "/blog/timesquare-goes-dark-mode",
        newPath: "/blog/timesquare-in-dark-mode"
    },
    {
        oldPath: "/blog/feature-5-interactive-maps-in-timesquare",
        newPath: "/blog/feature-interactive-map"
    },
    {
        oldPath: "/blog/timesquare-or-a-custom-app",
        newPath: "/blog/an-app-in-tmsqr-or-a-custom-app"
    },
    {
        oldPath: "/blog/feature-4-instagram-and-spotify-integration",
        newPath: "/blog/feature-spotify-integration"
    },
    {
        oldPath: "/blog/a-night-at-the-museum-with-timesquare",
        newPath: "/blog/a-night-at-the-museum-with-tmsqr"
    },
    {
        oldPath: "/blog/restart-with-timesquare",
        newPath: "/blog/restart-with-tmsqr"
    },
    {
        oldPath: "/blog/cooperation-with-design-students-from-tu-delft",
        newPath: "/blog/tmsqr-redesign-in-cooperation-with-design-students-from-tu-delft"
    },
    {
        oldPath: "/blog/meet-the-festivals-haldern-pop",
        newPath: "/blog/meet-the-festivals-haldern-pop-festival"
    },
    {
        oldPath: "/blog/meet-the-festivals-stadmakerscongres",
        newPath: "/blog/meet-the-festivals-city-makers-congress "
    },
    {
        oldPath: "/blog/launch-your-own-separate-ios-and-android-app-with-timesquare",
        newPath: "/blog/launch-your-own-ios-and-android-app-with-timesquare-technology"
    },
    {
        oldPath: "/blog/timesquare-at-esns",
        newPath: "/blog/tmsqr-at-esns"
    },
    {
        oldPath: "/blog/meet-the-festivals-ment",
        newPath: "/blog/meet-the-festivals-ment-ljubljana"
    },
    {
        oldPath: "/blog/10-tips-for-promoting-your-app",
        newPath: "/blog"
    },
    {
        oldPath: "/blog/meet-the-festivals-stadmakerscongres",
        newPath: "/blog/meet-the-festivals-city-makers-congress"
    },
    {
        oldPath: "/home.html",
        newPath: "/"
    },
];