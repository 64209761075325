import styled, {css} from 'styled-components';
import {Link} from "react-router-dom";

export const Card = styled(Link)`
    background-color: var(--color-white);
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
    width: ${({$skeletonLoading}) => $skeletonLoading ? '260px' : '100%'};
    height: 100%;
    transition: .2s ease box-shadow;
    
    @media screen and (max-width: 992px) {
        padding: 24px;
    }
    
    @media screen and (max-width: 768px) {
        flex-basis: 280px;
        flex-shrink: 0;
        ${({$isInSlider}) => !$isInSlider && css`height: unset;`};
    }

    ${({$isLink}) => $isLink && css`
        &:hover {
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        }
    `};
    
    ${({$isInSlider}) => $isInSlider && css`
        max-width: 260px;
        display: flex;
        flex-direction: column;
    `};
`;

export const Header = styled.header`
    margin-bottom: 16px;
`;

export const Figure = styled.figure``;

export const Img = styled.img`
    display: block;
    width: 50px;
    height: 50px;
`;

export const Title = styled.div`
    svg {
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }
`;

export const IconBox = styled.span`
    display: inline;
    white-space: nowrap;
`;