import styled from 'styled-components';

export const StyledTextHero = styled.section`
    position: relative;
    padding: 200px 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.04) 0%, rgba(0,0,0,0) 100%);
    overflow: hidden;
    margin-bottom: 60px;

    svg {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 95;
        width: 100%;
        margin-bottom: -4px;
    }

    @media screen and (max-width: 1024px) {
        padding: 150px 0;
    }
`;