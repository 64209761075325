import styled from 'styled-components';

export const USP = styled.section`
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    
    @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(1, 1fr);
    }

    article {
        margin: 0;
    }
`;