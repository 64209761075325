import {defer, useLoaderData} from "react-router-dom";
import React from "react";
import HomeHero from "../../blocks/HomeHero/HomeHero";
import InfoBanner from "../../blocks/InfoBanner/InfoBanner";
import CardParallax from "../../blocks/CardParallax/CardParallax";
import Atmospheric from "../../blocks/Atmospheric/Atmospheric";
import TextImageList from "../../blocks/TextImageList/TextImageList";
import IntegrationParallax from "../../blocks/IntegrationParallax/IntegrationParallax";
import TextParallax from "../../blocks/TextParallax/TextParallax";
import TestimonialSlider from "../../blocks/TestimonialSlider/TestimonialSlider";
import {TextImage} from "../../components";
import teamInOffice from "../../assets/img/blocks/textImage/team-in-office.jpg";
import styled from "styled-components";
import {Seo} from "../../components/Seo/Seo";

const Spacing = styled.div`
    padding: 80px 0;
    
    @media screen and (max-width: 768px) {
        padding: 40px 0;
    }
`;

export async function homeLoader({request, params}) {
    const lang = params?.lang;
    
    const homeData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/home${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    const advantagesData = fetch(`${process.env.REACT_APP_API_BASE_URL}/advantages${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    const textImageData = fetch(`${process.env.REACT_APP_API_BASE_URL}/textimage${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })


    const partnersData = fetch(`${process.env.REACT_APP_API_BASE_URL}/partners${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    const testimonialsData = fetch(`${process.env.REACT_APP_API_BASE_URL}/testimonials${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    return defer({homeData, advantagesData, textImageData, partnersData, testimonialsData});
}

const Home = () => {
    const {homeData, advantagesData, textImageData, partnersData, testimonialsData} = useLoaderData();

    return (
        <>
            <Seo
                title={homeData[0]?.metaTitle ? homeData[0]?.metaTitle : "Custom built apps for all types of festivals and events"}
                description={homeData[0]?.metaDescription}
                slug="/"
            />

            <HomeHero data={homeData}/>
            <InfoBanner data={homeData}/>
            <CardParallax data={advantagesData}/>
            <Atmospheric data={homeData}/>
            <TextImageList data={textImageData}/>
            <IntegrationParallax data={partnersData} />
            <Spacing>
                <TextImage image={teamInOffice} radius="true" title={homeData[0]?.optionsTitle} text={homeData[0]?.optionsText} reverse={false} />
            </Spacing>
            <TextParallax data={homeData}/>
            <TestimonialSlider data={testimonialsData} />
        </>
    )
}

export default Home
