import React, { useState, useEffect } from 'react';
import * as S from "./About.elements";
import {TextImage, Wrapper, Heading, Container, CoreValue, ContainerSmall, TextLine, Process} from "../../components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import a from '../../assets/img/about/1.jpg';
import b from '../../assets/img/about/2.jpg';
import c from '../../assets/img/about/3.jpg';
import d from '../../assets/img/about/4.jpg';
import e from '../../assets/img/about/5.jpg';
import teamInOffice from "../../assets/img/blocks/textImage/team-in-office.jpg";
import Hero from "../../blocks/Hero/Hero";
import {Await, defer, useLoaderData} from "react-router-dom";
import TextSlider from "../../components/TextSlider/TextSlider";
import {Seo} from "../../components/Seo/Seo";
import {RichText} from "../../components/Text/Text";
import {parseHTMLText} from "../../helpers/parseHTMLText";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import Team from "../../components/Team/Team";
import {useTranslation} from "react-i18next";

export async function aboutLoader({request, params}) {
    const lang = params?.lang;

    const aboutData = await fetch(`${process.env.REACT_APP_API_BASE_URL}/about-page${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const approachData = fetch(`${process.env.REACT_APP_API_BASE_URL}/approach${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const coreValuesData = fetch(`${process.env.REACT_APP_API_BASE_URL}/corevalues${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const teamData = fetch(`${process.env.REACT_APP_API_BASE_URL}/team${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    return defer({aboutData, approachData, coreValuesData, teamData});
}


const About = () => {
    const {t} = useTranslation();
    const {langForInUrl} = useCurrentLangCode();
    const {aboutData, approachData, coreValuesData, teamData} = useLoaderData();

    // Carousel settings
    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        initialSlide: 1,
    };

    // Handle carousel
    const [carousel, setCarousel] = useState(false);

    const showCarousel = () => {
        if(window.innerWidth <= 920) {
            setCarousel(true);
        } else {
            setCarousel(false);
        }
    };

    useEffect(() => {
        showCarousel();

        window.addEventListener('resize', showCarousel);
        return () => window.removeEventListener('resize', showCarousel);
    }, []);

    // Arrays
    const sliderData = [
        { id: '1', image: a },
        { id: '2', image: b, direction: 'horizontal' }, 
        { id: '3', image: c, direction: 'vertical' }, 
        { id: '4', image: d, direction: 'horizontal' }, 
        { id: '5', image: e }
    ]

    return (
        <>
            <Seo
                title={t('navbar.about_label')}
                description={aboutData[0]?.metaDescription}
                slug="/about"
            />

            <Hero
                type="overlap"
                heading={aboutData[0]?.title}
                text={aboutData[0]?.text}
                $about
            />

            <S.AboutContainer>
                <Container>
                    { carousel ?
                        <S.StyledSlider {...settings}>
                            {sliderData.map(item => (
                                <S.Slide key={item.id} >
                                    <img src={item.image} alt="" />
                                </S.Slide>
                            ))}
                        </S.StyledSlider>
                    :
                        <S.Grid>
                            {sliderData.map(item => (
                                <S.Item key={item.id} className={item?.direction} >
                                    <S.Img src={item.image} alt="" />
                                </S.Item>
                            ))}
                        </S.Grid>
                    }
                </Container>
            </S.AboutContainer>

            <TextLine heading={aboutData[0]?.textLineTitle} text={aboutData[0]?.textLineText}/>

            <TextSlider radius="true" title={aboutData[0]?.aboutTitle} text={aboutData[0]?.aboutText}/>

            <Team data={teamData} />

            <Process data={approachData}/>

            <S.Spacing>
                <TextImage image={teamInOffice} radius="true" title={aboutData[0]?.optionsTitle} text={aboutData[0]?.optionsText} reverse={false} />
            </S.Spacing>

            <React.Suspense>
                <Await resolve={coreValuesData}>
                    {(coreValuesData) => (
                        <Wrapper $exclude="top" last="true">
                            <ContainerSmall>
                                <Heading tag="h2">{coreValuesData[0]?.title}</Heading>
                                <RichText dangerouslySetInnerHTML={{__html: parseHTMLText(coreValuesData[0]?.text, langForInUrl)}} />
                            </ContainerSmall>
                            <Container>
                                <S.CoreValues>
                                    {coreValuesData[0]?.coreValues?.map(item => (
                                        <CoreValue key={item?.id} image={item?.icon} title={item?.title} text={item?.text} langForInUrl={langForInUrl}/>
                                    ))}
                                </S.CoreValues>
                            </Container>
                        </Wrapper>
                    )}
                </Await>
            </React.Suspense>

        </>
    );
}

export default About
