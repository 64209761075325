import React from 'react'
import * as S from './BulletSection.elements';
import placeholder from '../../assets/img/placeholder.svg';
import Wrapper from "../Wrapper/Wrapper";
import Container from "../Container/Container";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";

const BulletSection = ({title, description, image, mockup, children, reversed = false, eager = false}) => {
    
    return (
        <Wrapper tertiary="true">
            <Container>
                <S.Flex>

                    <S.Section>
                        <Heading textOnSurface tag="h2">{title}</Heading>
                        {description && <Text textOnSurface>{description}</Text>}
                        <S.Grid> 
                            {children}
                        </S.Grid>
                    </S.Section>

                    <S.Images>
                        {mockup && <S.Mockup src={mockup || placeholder} alt={''} loading={eager ? "eager" : "lazy"} $reversed={reversed}/>}
                        <S.Img src={image || placeholder} alt={''} loading={eager ? "eager" : "lazy"} $reversed={reversed} $noMockup={!mockup} />
                    </S.Images>

                </S.Flex>
            </Container>
        </Wrapper>
    )
}

export default BulletSection
