import styled, { css } from 'styled-components';
import { ContainerSmall } from '../../components';

export const Hero = styled.section`
    position: relative;
    padding-top: 200px;
    background: linear-gradient(0deg, rgba(0,0,0,0.04) 0%, rgba(0,0,0,0) 100%);
    overflow: hidden;

    ${props => props.type === 'home' && css ` min-height: 1100px; `}
    ${props => props.type === 'functionality' && css ` min-height: 700px; `}

    svg {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 95;
        width: 100%;
        margin-bottom: -4px;
    }

    @media screen and (max-width: 1024px) {
        padding-top: 150px;
    }
`;

export const HeroText = styled(ContainerSmall)`
    margin-bottom: 80px;
    ${props => props.type === 'overlap' && css`
        padding-bottom: 300px;`
    }}
    
    ${props => props.$small && css`
        padding-bottom: 300px;

        @media screen and (max-width: 576px) {
            padding-bottom: 150px;
        }
    `};
    
    ${props => props.type === 'customer' && css`
        padding-bottom: 300px;
        
        @media screen and (max-width: 425px) {
            padding-bottom: 210px;
        }
        
        @media screen and (max-width: 375px) {
            padding-bottom: 150px;
        }
    `};
`;

export const DecorationHexagon = styled.img`
    display: block;
    transform: rotate(20deg);
    position: absolute;

    ${props => props.type === 'home' && css`
        bottom: -160px;
        left: -160px;
    `}

    ${props => props.type !== 'home' && css`
        bottom: -40px;
        right: -160px;
    `}

    width: 500px;
    height: 500px;
    transition: width .2s ease, height .2s ease, bottom .2s ease;

    @media screen and (max-width: 1024px) {
        width: 400px;
        height: 400px;
    }

    @media screen and (max-width: 425px) {
        bottom: ${props => props.type === 'customer' ? '-130px' : '-70px'};
        width: 350px;
        height: 350px;
    }

    ${props => props.$small && css`
        @media screen and (max-width: 576px) {
            bottom: -110px;
            width: 350px;
            height: 350px;
        }

        @media screen and (max-width: 425px) {
            bottom: -170px;
        }
    `}
`;

export const PhoneImg = styled.img`
    position: relative;
    z-index: 1;
    max-width: 350px;
    width: 100%;
    object-fit: contain;
    height: 460px;
    transition: width .2s ease, height .2s ease;

    @media screen and (max-width: 425px) {
        width: 80%;
        height: auto;
    }
`;

export const FloatingElements = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 100%;
    z-index: 97;
    max-width: 1920px;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

export const BoundingBox = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;